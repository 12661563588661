import proj4 from 'proj4'
import DegreePosition from './DegreePosition'
const epsg2056 = require('epsg-index/s/2056')
const epsg4326 = require('epsg-index/s/4326')

export const renderPx = (val: number): string => {
  return `${val}px`
}

export const epsg2056ToDegreePosition = (E: number, N: number): DegreePosition => {
  const reprojection = proj4(epsg2056.proj4, epsg4326.proj4, [E, N])
  return new DegreePosition(reprojection[1], reprojection[0])
}

// Date Utils
export const getDateWeek = (date: Date): number => {
  const onejan = new Date(date.getFullYear(),0,1);
  const today = new Date(date.getFullYear(),date.getMonth(),date.getDate());
  const dayOfYear = ((today.getTime() - onejan.getTime() + 86400000)/86400000);
  return Math.ceil(dayOfYear/7)
}

export const getMondayOfWeek = (week: number): Date => {
  const d = (1 + (week) * 7)
  const res = new Date(new Date().getFullYear(), 0, d)
  while (res.getDay() !== 1) {
    res.setDate(res.getDate() - 1)
  }
  return res
}

export const getSundayOfWeek = (week: number): Date => {
  const sunday = getMondayOfWeek(week)
  sunday.setDate(sunday.getDate() + 6)
  return sunday
}

export const getTodayWeek = (): number => {
  return getDateWeek(new Date())
}

export const render2DigitNumber = (number: number): string => {
  return `${number < 10 ? '0' : ''}${number}`
}

export const renderDate = (date: Date): string => {
  return `${render2DigitNumber(date.getDate())}.${render2DigitNumber(date.getMonth() + 1)}.${date.getFullYear()}`
}