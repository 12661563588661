import { BACKEND_REMOTE_HOST, MVT_PORT } from "@/constants";

export default {
  "version": 8,
  "name": "lbm_v1.0.0",
  "metadata": {"maputnik:renderer": "mbgljs", "openmaptiles:version": "3.x"},
  "center": [7.969664962869274, 47.021278030856564],
  "zoom": 9.333879434342306,
  "bearing": 0,
  "pitch": 0,
  "sources": {
    "swissmaptiles": {
      "type": "vector",
      "url": "https://vectortiles.geo.admin.ch/tiles/ch.swisstopo.leichte-basiskarte.vt/v1.0.0/tiles.json"
    },
    "relief_shading": {
      "type": "raster",
      "tiles": [
        "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.leichte-basiskarte_reliefschattierung/default/current/3857/{z}/{x}/{y}.png"
      ],
      "tileSize": 256,
      "minzoom": 0,
      "maxzoom": 22
    },
    "swissimage": {
      "type": "raster",
      "tiles": [
        "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
      ],
      "tileSize": 256,
      "minzoom": 0,
      "maxzoom": 22
    },
    "bilanscontraintes": {
      "type": "vector",
      "tiles": [
      `http://${BACKEND_REMOTE_HOST}${MVT_PORT}/bilanscontraintes/{z}/{x}/{y}.pbf`
      ]
    },
    "contraintes": {
      "type": "vector",
      "tiles": [
        `http://${BACKEND_REMOTE_HOST}${MVT_PORT}/contraintes/{z}/{x}/{y}.pbf`
      ]
    },
    "contraintes_buffer": {
      "type": "vector",
      "tiles": [
        `http://${BACKEND_REMOTE_HOST}${MVT_PORT}/contraintes_buffer/{z}/{x}/{y}.pbf`
      ]
    }
  },
  "sprite": "https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/v1.0.0/sprite/sprite",
  "glyphs": "https://vectortiles.geo.admin.ch/fonts/{fontstack}/{range}.pbf",
  "transition": {},
  "layers": [
    {
      "id": "background",
      "type": "background",
      "layout": {"visibility": "visible"},
      "paint": {"background-color": "rgba(255, 255, 255, 1)"}
    },
    {
      "id": "relief_shading",
      "type": "raster",
      "source": "relief_shading",
      "layout": {"visibility": "visible"},
      "paint": {
        "raster-opacity": {"stops": [[15.5, 1], [17, 0.6]]},
        "raster-brightness-min": [
          "interpolate",
          ["linear"],
          ["zoom"],
          6,
          0,
          12,
          0.25
        ],
        "raster-saturation": [
          "interpolate",
          ["linear"],
          ["zoom"],
          6,
          -0.5,
          12,
          -0.2
        ],
        "raster-contrast": ["interpolate", ["linear"], ["zoom"], 6, 0.1, 12, 0],
        "raster-resampling": "nearest"
      }
    },
    {
      "id": "contour_line",
      "type": "line",
      "metadata": {"maputnik:comment": "color: rgb(191,138,64)"},
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 13,
      "filter": ["all", ["!in", "class", "rock", "ice", "water"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.4,
        "line-color": [
          "match",
          ["get", "class"],
          ["ice", "water"],
          "rgb(0,136,208)",
          ["scree"],
          "rgb(0,0,0)",
          "rgb(191,138,64)"
        ],
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          13,
          ["match", ["get", "class"], ["scree", "ice", "water"], 0.25, 0.45],
          14,
          ["match", ["get", "class"], ["scree", "ice", "water"], 0.3, 0.55]
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          13,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          14,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 100], 0], 1, 0],
          14.5,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            1.5,
            [
              "case",
              ["==", ["%", ["to-number", ["get", "ele"]], 20], 0],
              0.75,
              0
            ]
          ],
          15,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            1.75,
            ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 1, 0]
          ],
          16.5,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            2,
            ["case", ["==", ["%", ["to-number", ["get", "ele"]], 10], 0], 1, 0]
          ]
        ]
      }
    },
    {
      "id": "landcover",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landcover",
      "minzoom": 5,
      "filter": [
        "any",
        [
          "in",
          "subclass",
          "wood",
          "scrub",
          "recreation_ground",
          "park",
          "golf_course",
          "allotments",
          "plant_nursery",
          "forest",
          "glacier"
        ],
        ["==", "class", "sand"]
      ],
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-color": [
          "match",
          ["get", "class"],
          ["forest", "wood"],
          "rgb(186, 210, 172)",
          ["ice", "glacier"],
          "rgb(205, 232, 244)",
          ["wetland"],
          "rgb(204, 229, 245)",
          ["sand"],
          "rgb(240, 218, 188)",
          "rgb(215, 224, 209)"
        ],
        "fill-opacity": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          0,
          6,
          ["match", ["get", "class"], ["ice", "glacier"], 0.3, 0],
          10,
          ["match", ["get", "class"], ["ice", "glacier"], 0.2, 0],
          11,
          ["match", ["get", "class"], ["ice", "glacier"], 0.2, 0.3],
          14,
          ["match", ["get", "class"], ["ice", "glacier"], 0, 0.3]
        ]
      }
    },
    {
      "id": "landcover_casing",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "landcover",
      "minzoom": 10,
      "filter": [
        "all",
        [
          "in",
          "subclass",
          "wood",
          "scrub",
          "recreation_ground",
          "park",
          "golf_course",
          "allotments",
          "plant_nursery",
          "forest"
        ]
      ],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.5,
        "line-color": "rgb(186, 199, 172)",
        "line-opacity": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          12,
          0,
          14,
          1
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          6,
          0,
          11,
          0.25,
          16,
          0.75
        ]
      }
    },
    {
      "id": "landuse",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landuse",
      "filter": [
        "all",
        ["in", "class", "landfill", "cemetery", "quarry", "zoo", "pitch"]
      ],
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-color": [
          "match",
          ["get", "class"],
          ["pitch"],
          "rgb(224,234,221)",
          ["landfill", "quarry"],
          "rgb(240, 218, 188)",
          ["cemetery", "zoo"],
          "rgb(215, 224, 209)",
          ["parking"],
          "rgb(255, 255, 255)",
          "rgb(215, 224, 209)"
        ],
        "fill-opacity": ["match", ["get", "class"], ["pitch"], 1, 0.3]
      }
    },
    {
      "id": "landuse_outline",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "landuse",
      "filter": ["all", ["in", "class", "cemetery", "zoo", "pitch"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-color": [
          "match",
          ["get", "class"],
          ["pitch"],
          "rgb(130, 130, 130)",
          "rgba(186, 199, 172, 1)"
        ],
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          11,
          0.25,
          16,
          0.75
        ]
      }
    },
    {
      "id": "water_line_intermittent",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "waterway",
      "minzoom": 12,
      "filter": ["all", ["==", "intermittent", 1]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0,
        "line-color": "rgba(48, 48, 48, 1)",
        "line-dasharray": {
          "stops": [
            [13, [0.5, 3]],
            [14, [0.75, 4.5]],
            [15, [1, 6]],
            [16, [1.25, 7.5]]
          ]
        },
        "line-offset": 0,
        "line-opacity": {"stops": [[13, 0.25], [16, 0.35]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          13,
          [
            "match",
            ["to-string", ["get", "width"]],
            ["10", "9", "8"],
            3.5,
            ["7", "6", "5"],
            2.5,
            ["4", "3"],
            1.5,
            ["1", "2"],
            1,
            ["match", ["get", "class"], ["river", "canal"], 3.5, 1]
          ],
          16,
          [
            "match",
            ["to-string", ["get", "width"]],
            "10",
            5,
            "9",
            4.5,
            "8",
            4,
            "7",
            3.5,
            ["6", "5"],
            3,
            "4",
            2.5,
            "3",
            2,
            "2",
            1.5,
            "1",
            1,
            ["match", ["get", "class"], ["river", "canal"], 5, 1.5]
          ],
          20,
          [
            "match",
            ["to-string", ["get", "width"]],
            "10",
            10,
            "9",
            9.6,
            "8",
            8.9,
            "7",
            8.2,
            "6",
            7.5,
            "5",
            6.8,
            "4",
            6.1,
            "3",
            5.4,
            "2",
            4.7,
            "1",
            4,
            ["match", ["get", "class"], ["river", "canal"], 10, 4]
          ]
        ]
      }
    },
    {
      "id": "water_line",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "waterway",
      "minzoom": 7,
      "filter": ["all", ["!=", "intermittent", 1]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0,
        "line-color": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          "rgba(138, 195, 229, 1)",
          16,
          [
            "match",
            ["get", "class"],
            ["river", "canal"],
            "rgba(160, 205, 236, 1)",
            "rgba(140, 185, 226, 1)"
          ]
        ],
        "line-offset": 0,
        "line-opacity": 0.7,
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          7,
          1,
          10,
          [
            "match",
            ["to-string", ["get", "width"]],
            ["10", "9", "8", "7", "6"],
            1.5,
            ["5", "4", "3", "2", "1"],
            1,
            ["match", ["get", "class"], ["river", "canal"], 1.5, 1]
          ],
          13,
          [
            "match",
            ["to-string", ["get", "width"]],
            ["10", "9", "8"],
            3.5,
            ["7", "6", "5"],
            2.5,
            ["4", "3"],
            1.5,
            ["1", "2"],
            1,
            ["match", ["get", "class"], ["river", "canal"], 3.5, 1]
          ],
          16,
          [
            "match",
            ["to-string", ["get", "width"]],
            "10",
            5,
            "9",
            4.5,
            "8",
            4,
            "7",
            3.5,
            ["6", "5"],
            3,
            "4",
            2.5,
            "3",
            2,
            "2",
            1.5,
            "1",
            1,
            ["match", ["get", "class"], ["river", "canal"], 5, 1.5]
          ],
          20,
          [
            "match",
            ["to-string", ["get", "width"]],
            "10",
            10,
            "9",
            9.6,
            "8",
            8.9,
            "7",
            8.2,
            "6",
            7.5,
            "5",
            6.8,
            "4",
            6.1,
            "3",
            5.4,
            "2",
            4.7,
            "1",
            4,
            ["match", ["get", "class"], ["river", "canal"], 10, 4]
          ]
        ]
      }
    },
    {
      "id": "water_polygon",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "water",
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-color": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          4,
          "rgb(209, 228, 240)",
          10,
          "rgb(199, 224, 245)"
        ]
      }
    },
    {
      "id": "contour_line_water",
      "type": "line",
      "metadata": {"maputnik:comment": "color: rgb(191,138,64)"},
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 13,
      "filter": ["all", ["in", "class", "ice", "water"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgb(0,136,208)",
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          13,
          ["match", ["get", "class"], ["scree", "ice", "water"], 0.25, 0.45],
          14,
          ["match", ["get", "class"], ["scree", "ice", "water"], 0.3, 0.55]
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          13,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          14,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 100], 0], 1, 0],
          14.5,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            1.5,
            [
              "case",
              ["==", ["%", ["to-number", ["get", "ele"]], 20], 0],
              0.75,
              0
            ]
          ],
          16,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            1.75,
            ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 1, 0]
          ],
          16.5,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            2,
            ["case", ["==", ["%", ["to-number", ["get", "ele"]], 10], 0], 1, 0]
          ]
        ]
      }
    },
    {
      "id": "label_contour_line_20_grey",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 16,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 20], 0],
        ["!=", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["scree"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": 550,
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 40,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": 12,
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(64,64,64, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, .8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "label_contour_line_20_blue",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 16,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 20], 0],
        ["!=", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["ice", "water"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": 550,
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 40,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": 12,
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(0,136,208, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, .8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "label_contour_line_20_brown",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 16,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 20], 0],
        ["!=", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["land"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": 550,
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 40,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": 12,
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(185,132,58, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, .8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "label_contour_line_100_grey",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 14,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["scree"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": {"stops": [[14, 250], [16, 450]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 35,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          10.5,
          16,
          14
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(64,64,64, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, 0.8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "label_contour_line_100_blue",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 14,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["ice", "water"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": {"stops": [[14, 250], [16, 450]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 35,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          10.5,
          16,
          14
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(0,136,208, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, 0.8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "label_contour_line_100_brown",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "contour_line",
      "minzoom": 14,
      "filter": [
        "all",
        ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
        ["match", ["get", "class"], ["land"], true, false]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": {"stops": [[14, 250], [16, 450]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{ele} ",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 35,
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          12,
          16,
          80
        ],
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          10.5,
          16,
          14
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(185,132,58, 1)",
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(238, 238, 238, 0.8)",
        "text-halo-width": 2,
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          12,
          [
            "case",
            ["==", ["%", ["to-number", ["get", "ele"]], 100], 0],
            0.75,
            0
          ],
          16,
          ["case", ["==", ["%", ["to-number", ["get", "ele"]], 20], 0], 0.75, 0]
        ]
      }
    },
    {
      "id": "water_outline",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "water",
      "filter": ["all", ["!=", "class", "ocean"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.5,
        "line-color": "rgba(133, 189, 224, 1)",
        "line-opacity": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          0,
          0,
          8,
          1,
          14,
          0
        ],
        "line-width": 0.5
      }
    },
    {
      "id": "pattern_landcover_vineyard",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landcover",
      "minzoom": 13,
      "filter": ["all", ["==", "subclass", "vineyard"]],
      "paint": {
        "fill-opacity": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          13,
          0,
          13.1,
          0.6,
          17,
          1
        ],
        "fill-pattern": "vinyard_green"
      }
    },
    {
      "id": "pattern_landcover_wetlands",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landcover",
      "minzoom": 13,
      "filter": ["all", ["==", "class", "wetland"]],
      "paint": {
        "fill-antialias": true,
        "fill-color": "#000000",
        "fill-opacity": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          13,
          0,
          13.2,
          0.4,
          17,
          1
        ],
        "fill-pattern": "wetland_blue"
      }
    },
    {
      "id": "pattern_landcover_orchard",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landcover",
      "minzoom": 13,
      "filter": ["all", ["==", "subclass", "orchard"]],
      "paint": {
        "fill-opacity": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          13,
          0,
          13.2,
          0.8,
          17,
          1
        ],
        "fill-pattern": "orchard_green"
      }
    },
    {
      "id": "park",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "park",
      "minzoom": 7,
      "filter": ["all", ["in", "class", "national_park"]],
      "paint": {
        "line-color": "rgba(171, 197, 159, 1)",
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          0,
          8,
          1,
          13,
          1,
          14,
          0.6
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          9,
          1.5,
          15,
          8,
          18,
          12
        ]
      }
    },
    {
      "id": "boundary_disputed",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "boundary",
      "minzoom": 0,
      "filter": [
        "all",
        ["in", "admin_level", 2, 4],
        ["!=", "maritime", 1],
        ["==", "disputed", 1],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": "square",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 1,
        "line-color": {
          "stops": [[6, "hsl(300, 55%, 80%)"], [9, "hsl(315, 40%, 85%)"]]
        },
        "line-dasharray": [1.5, 2.5],
        "line-opacity": {"stops": [[13, 1], [14, 0.7]]},
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          2,
          ["match", ["get", "admin_level"], 2, 1, 0],
          3,
          ["match", ["get", "admin_level"], 2, 2, 1],
          7,
          ["match", ["get", "admin_level"], 2, 4, 1.5],
          18,
          ["match", ["get", "admin_level"], 2, 16, 10]
        ]
      }
    },
    {
      "id": "boundary",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "boundary",
      "minzoom": 0,
      "filter": [
        "all",
        ["in", "admin_level", 2, 4],
        ["!=", "maritime", 1],
        ["!=", "disputed", 1],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": ["literal", "round"],
        "line-join": ["literal", "miter"],
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 1,
        "line-color": {
          "stops": [[6, "hsl(300, 55%, 80%)"], [9, "hsl(315, 40%, 85%)"]]
        },
        "line-opacity": {"stops": [[13, 1], [14, 0.8]]},
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          2,
          ["match", ["get", "admin_level"], 2, 1, 0],
          3,
          ["match", ["get", "admin_level"], 2, 2, 1],
          7,
          ["match", ["get", "admin_level"], 2, 3, 1],
          18,
          ["match", ["get", "admin_level"], 2, 16, 10]
        ]
      }
    },
    {
      "id": "tunnel_public_transport",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 8,
      "filter": [
        "all",
        ["==", "brunnel", "tunnel"],
        ["in", "class", "rail", "transit", "cable_car", "gondola"],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          18,
          0.4
        ],
        "line-color": {
          "stops": [[7, "rgba(255, 50, 50, 1)"], [15, "rgba(255, 80, 80, 1)"]]
        },
        "line-dasharray": {
          "stops": [
            [13, [4, 2.5]],
            [14, [6, 3.75]],
            [15, [10, 6.25]],
            [16, [14, 8.75]]
          ]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0,
          9,
          ["match", ["get", "class"], ["rail"], 0.8, 0],
          13,
          [
            "match",
            ["get", "subclass"],
            ["rail", "subway", "funicular", "narrow_gauge"],
            0.8,
            0
          ],
          14,
          [
            "match",
            ["get", "subclass"],
            ["rail", "narrow_gauge", "funicular", "subway"],
            ["match", ["get", "service"], ["yard", "siding"], 0, 0.8],
            0
          ],
          14.5,
          0.8
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.2, 0.75],
              0.2
            ],
            0.2
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.1, 1.25],
              1
            ],
            ["cable_car"],
            1,
            0.75
          ],
          18,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 1, 2],
              2
            ],
            ["cable_car"],
            2,
            1.5
          ]
        ]
      }
    },
    {
      "id": "tunnel_road",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 7,
      "filter": [
        "all",
        ["==", "brunnel", "tunnel"],
        [
          "!in",
          "class",
          "rail",
          "ferry",
          "transit",
          "cable_car",
          "gondola",
          "path"
        ],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          ["literal", 3],
          8,
          ["literal", 1],
          9,
          ["match", ["get", "class"], ["motorway", "trunk", "primary"], 0.4, 3],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary"],
            0.4,
            3
          ],
          11,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary"],
            0.4,
            3
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary", "tertiary"],
            0.4,
            3
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary", "tertiary"],
            0.4,
            ["minor"],
            1,
            3
          ],
          15,
          0.4
        ],
        "line-color": "rgba(170, 170, 170, 1)",
        "line-dasharray": {
          "stops": [
            [13, [3, 1.875]],
            [14, [4, 2.5]],
            [15, [5, 3.125]],
            [16, [6, 3.75]]
          ]
        },
        "line-offset": 0,
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          ["literal", 0],
          8,
          ["match", ["get", "class"], ["motorway", "trunk", "primary"], 1, 0],
          9,
          ["match", ["get", "class"], ["motorway", "trunk", "primary"], 1.1, 0],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary"],
            1.2,
            0
          ],
          11,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary"],
            1.3,
            0
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "secondary", "tertiary"],
            1.4,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            1.5,
            ["primary", "secondary", "tertiary", "minor"],
            1,
            0
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            2,
            ["primary", "secondary", "tertiary", "minor"],
            1.5,
            0
          ],
          15,
          ["match", ["get", "class"], ["motorway", "trunk"], 3, 2],
          20,
          ["match", ["get", "class"], ["motorway", "trunk"], 5, 2.5]
        ]
      }
    },
    {
      "id": "tunnel_road_path",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 6,
      "filter": [
        "all",
        ["==", "brunnel", "tunnel"],
        ["==", "class", "path"],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgba(140, 140, 140, 1)",
        "line-dasharray": {
          "stops": [
            [14, [1, 0.6]],
            [15, [1.5, 0.9]],
            [16, [2, 1.2]],
            [17, [3, 1.8]],
            [18, [6, 3.6]]
          ]
        },
        "line-offset": 0,
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          15,
          1.1,
          20,
          3
        ]
      }
    },
    {
      "id": "construct_line",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "construct",
      "minzoom": 14,
      "filter": ["all", ["==", "class", "lock"]],
      "paint": {
        "line-blur": 0.4,
        "line-color": "hsl(220, 10%, 67%)",
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": {"stops": [[15, 1], [18, 4]]}
      }
    },
    {
      "id": "construct",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "construct",
      "minzoom": 13,
      "filter": ["all", ["!=", "class", "lock"]],
      "paint": {
        "fill-color": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          13,
          [
            "match",
            ["get", "class"],
            "weir",
            "hsl(220, 10%, 82%)",
            "hsl(220, 10%, 86%)"
          ],
          17,
          [
            "match",
            ["get", "class"],
            "weir",
            "hsl(220, 10%, 75%)",
            "dam",
            "hsl(220, 10%, 82%)",
            "hsl(220, 10%, 86%)"
          ]
        ],
        "fill-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          12,
          0,
          13,
          ["match", ["get", "class"], "dam", 1, 0],
          14,
          ["match", ["get", "class"], "dam", 1, 0],
          15,
          1
        ]
      }
    },
    {
      "id": "aeroway_polygon_casing",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "aeroway",
      "minzoom": 11,
      "filter": [
        "all",
        ["in", "class", "runway", "taxiway", "apron", "runway_grass"],
        ["==", "$type", "Polygon"]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgb(190, 190, 190)",
        "line-opacity": 1,
        "line-width": {"base": 1.5, "stops": [[13, 3], [15, 4], [17, 5]]}
      }
    },
    {
      "id": "aeroway_line_casing",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "aeroway",
      "minzoom": 11,
      "filter": ["all", ["==", "$type", "LineString"]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgba(110, 110, 110, 1)",
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11.5,
          0,
          12,
          2.3,
          15,
          11.25,
          20,
          153
        ]
      }
    },
    {
      "id": "road_via_ferrata",
      "type": "line",
      "metadata": {
        "maputnik:comment": "cannot be in the same layer as road_casings because dasharray doesn't support expressions"
      },
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        ["in", "class", "via_ferrata"],
        ["!in", "brunnel", "bridge", "tunnel"],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "bevel",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[15, 0.1], [20, 0.4]]},
        "line-color": {
          "stops": [[15, "rgba(20, 20, 20, 1)"], [18, "rgba(90, 90, 90, 1)"]]
        },
        "line-dasharray": {
          "stops": [
            [15, [0.75, 2]],
            [16, [1.125, 3]],
            [17, [1.5, 4]],
            [18, [1.875, 5]]
          ]
        },
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          15,
          1.1,
          20,
          3
        ]
      }
    },
    {
      "id": "road_path",
      "type": "line",
      "metadata": {
        "maputnik:comment": "cannot be in the same layer as road_casings because dasharray doesn't support expressions"
      },
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        ["match", ["get", "class"], ["path"], true, false],
        [
          "match",
          ["get", "brunnel"],
          ["bridge", "tunnel"],
          ["match", ["get", "subclass"], "covered_bridge", true, false],
          true
        ],
        ["==", ["geometry-type"], "LineString"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "bevel",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[15, 0.1], [20, 0.4]]},
        "line-color": "rgba(115, 115, 115, 1)",
        "line-dasharray": {
          "stops": [
            [14, [3, 1]],
            [15, [6, 2]],
            [16, [9, 3]],
            [17, [12, 4]],
            [18, [15, 5]]
          ]
        },
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          15,
          1.1,
          20,
          3
        ]
      }
    },
    {
      "id": "building_ln",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "building_ln",
      "minzoom": 13,
      "paint": {
        "line-blur": 0.4,
        "line-color": [
          "match",
          ["get", "class"],
          "weir",
          "rgb(20, 136, 205)",
          "rgb(130, 130, 130)"
        ],
        "line-opacity": ["match", ["get", "class"], "weir", 0.2, 1],
        "line-width": {"stops": [[13, 0], [16, 2], [18, 3]]}
      }
    },
    {
      "id": "road_casing",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 7,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "cable_car",
            "car_ferry",
            "chair_lift",
            "ferry",
            "gondola",
            "path",
            "rail",
            "transit",
            "via_ferrata"
          ],
          false,
          true
        ],
        [
          "match",
          ["get", "brunnel"],
          ["bridge", "tunnel"],
          ["match", ["get", "subclass"], "covered_bridge", true, false],
          true
        ],
        ["==", ["geometry-type"], "LineString"]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[7, 3], [8, 0.4]]},
        "line-color": [
          "interpolate",
          ["exponential", 1.7],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(170, 136, 30)",
            "rgb(80, 80, 80)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(139, 107, 63)",
            "rgb(90, 90, 90)"
          ]
        ],
        "line-offset": 0,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          7,
          ["literal", 0],
          8,
          ["match", ["get", "ramp"], 1, 1.5, 3],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 4],
            ["trunk"],
            4,
            3
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5, 5],
            ["primary"],
            5,
            ["secondary"],
            4.5,
            ["tertiary"],
            3.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 3.5, 5.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5.5, 5.5],
            ["primary"],
            5.5,
            ["secondary"],
            5,
            ["tertiary"],
            4,
            ["minor"],
            3,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 6.5, 8],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 8, 9],
            ["primary"],
            9,
            ["secondary"],
            8,
            ["tertiary"],
            7,
            ["minor"],
            5,
            ["track"],
            1.1,
            4
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 10, 12],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 12, 15],
            ["primary"],
            15,
            ["secondary"],
            13,
            ["tertiary"],
            11,
            ["minor"],
            9,
            ["track"],
            1.1,
            8
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            120,
            ["secondary"],
            115,
            ["tertiary"],
            110,
            ["minor"],
            100,
            ["track"],
            3,
            95
          ]
        ]
      }
    },
    {
      "id": "landuse_parking",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "landuse",
      "filter": ["all", ["in", "class", "parking"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-color": "rgba(255, 255, 255, 1)",
        "fill-opacity": {"stops": [[14, 0], [15, 1]]}
      }
    },
    {
      "id": "landuse_parking_outline",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "landuse",
      "filter": ["all", ["in", "class", "parking"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-color": "rgba(130, 130, 130, 1)",
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          11,
          0.25,
          16,
          0.75
        ]
      }
    },
    {
      "id": "road_fill",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 7,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "cable_car",
            "car_ferry",
            "chair_lift",
            "ferry",
            "gondola",
            "path",
            "rail",
            "track",
            "transit",
            "via_ferrata"
          ],
          false,
          true
        ],
        [
          "match",
          ["get", "brunnel"],
          ["bridge", "tunnel"],
          ["match", ["get", "subclass"], "covered_bridge", true, false],
          true
        ],
        ["==", ["geometry-type"], "LineString"]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[8, 0.4], [14, 0.1]]},
        "line-color": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255,230,160)",
            "rgb(255,255,255)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255, 224, 138)",
            "rgb(255,255,255)"
          ]
        ],
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          7,
          ["literal", 0],
          8,
          ["match", ["get", "ramp"], 1, 0.5, 2],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 2.75],
            ["trunk"],
            2.75,
            2
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 3.75],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 3.75, 3.75],
            ["primary"],
            3.75,
            ["secondary"],
            3.25,
            ["tertiary"],
            2.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.75, 4.25],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 4.25, 4.25],
            ["primary"],
            4.25,
            ["secondary"],
            3.75,
            ["tertiary"],
            2.75,
            ["minor"],
            1.75,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 5, 6.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 6.5, 7.5],
            ["primary"],
            7.5,
            ["secondary"],
            6.5,
            ["tertiary", "rail"],
            5.5,
            ["minor"],
            3.5,
            2.5
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 8.5, 10.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 10.5, 13.5],
            ["primary"],
            13.5,
            ["secondary"],
            11.5,
            ["tertiary", "rail"],
            9.5,
            ["minor"],
            7.5,
            6.5
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            117,
            ["secondary"],
            112,
            ["tertiary"],
            107,
            ["minor"],
            97,
            92
          ]
        ]
      }
    },
    {
      "id": "aeroway_line_fill",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "aeroway",
      "minzoom": 11,
      "filter": ["all", ["==", "$type", "LineString"]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": [
          "match",
          ["get", "class"],
          ["runway_grass"],
          "rgb(224,234,221)",
          "rgb(255, 255, 255)"
        ],
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          11,
          0,
          12,
          2,
          15,
          10,
          20,
          150
        ]
      }
    },
    {
      "id": "aeroway_polygon_fill",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "aeroway",
      "minzoom": 11,
      "filter": [
        "all",
        ["in", "class", "runway", "taxiway", "apron", "runway_grass"],
        ["==", "$type", "Polygon"]
      ],
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-antialias": true,
        "fill-color": [
          "match",
          ["get", "class"],
          ["runway_grass"],
          "rgb(224,234,221)",
          "rgb(255, 255, 255)"
        ],
        "fill-opacity": 1
      }
    },
    {
      "id": "car_ferry",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 9,
      "filter": ["all", ["==", "class", "car_ferry"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgba(105, 187, 218, 1)",
        "line-dasharray": {
          "stops": [
            [12, [6, 2]],
            [13, [9, 3]],
            [14, [12, 4]],
            [15, [15, 5]],
            [16, [18, 6]]
          ]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          9,
          0,
          11,
          ["match", ["get", "class"], ["car_ferry"], 1, 0],
          12,
          ["match", ["get", "class"], ["car_ferry"], 1, 0],
          13,
          1
        ],
        "line-width": {"stops": [[8, 0.4], [14, 1], [18, 2]]}
      }
    },
    {
      "id": "ferry",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 12,
      "filter": ["all", ["==", "class", "ferry"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": 0.4,
        "line-color": "rgba(105, 187, 218, 1)",
        "line-dasharray": {
          "stops": [
            [12, [3, 1]],
            [13, [6, 2]],
            [14, [9, 3]],
            [15, [12, 4]],
            [16, [15, 5]]
          ]
        },
        "line-opacity": {"stops": [[12, 0], [13, 1]]},
        "line-width": {"stops": [[8, 0.4], [14, 1], [18, 2]]}
      }
    },
    {
      "id": "public_transport",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 8,
      "filter": [
        "all",
        ["in", "class", "rail", "transit"],
        ["!in", "brunnel", "bridge", "tunnel"]
      ],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          18,
          0.4
        ],
        "line-color": {
          "stops": [[7, "rgba(255, 50, 50, 1)"], [15, "rgba(255, 80, 80, 1)"]]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0,
          9,
          ["match", ["get", "class"], ["rail"], 1, 0],
          12,
          ["match", ["get", "class"], ["rail"], 1, 0],
          13,
          ["match", ["get", "class"], ["rail"], 1, 0],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge", "funicular"],
              ["match", ["get", "service"], ["yard", "siding"], 0, 1],
              0
            ],
            0
          ],
          14.5,
          1
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.2, 0.5],
              0.2
            ],
            0.2
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.2, 1],
              1
            ],
            ["cable_car"],
            1,
            0.75
          ],
          18,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 1.5, 2],
              2
            ],
            1.5
          ]
        ]
      }
    },
    {
      "id": "sinkhole",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "spot_elevation",
      "minzoom": 14,
      "filter": [
        "all",
        [
          "in",
          "class",
          "sinkhole",
          "sinkhole_rock",
          "sinkhole_scree",
          "sinkhole_water",
          "sinkhole_ice"
        ]
      ],
      "layout": {
        "icon-anchor": "bottom",
        "icon-image": [
          "match",
          ["get", "class"],
          "sinkhole",
          "arrow_brown",
          ["sinkhole_rock", "sinkhole_scree"],
          "arrow_grey",
          ["sinkhole_ice", "sinkhole_water"],
          "arrow_blue",
          ""
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 30,
        "icon-rotate": 15,
        "icon-size": 1,
        "symbol-placement": "point",
        "text-allow-overlap": false,
        "text-anchor": "center",
        "text-field": "",
        "text-font": [],
        "text-ignore-placement": false,
        "text-keep-upright": false,
        "text-optional": false,
        "text-size": 16,
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "#000000",
        "icon-opacity": {"stops": [[14, 0], [15, 0.6]]},
        "text-color": "#000000",
        "text-halo-blur": 1,
        "text-halo-width": 0,
        "text-opacity": 1
      }
    },
    {
      "id": "building_2d",
      "type": "fill",
      "source": "swissmaptiles",
      "source-layer": "building",
      "minzoom": 13,
      "layout": {"visibility": "visible"},
      "paint": {
        "fill-color": {
          "stops": [[13, "hsl(220, 10%, 82%)"], [17, "hsl(220, 10%, 75%)"]]
        },
        "fill-opacity": {"stops": [[13, 0], [13.5, 1]]}
      }
    },
    {
      "id": "building_2d_casing",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "building",
      "minzoom": 15,
      "layout": {"visibility": "visible"},
      "paint": {
        "line-color": "hsl(220, 10%, 67%)",
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          16,
          0.5,
          19,
          1
        ],
        "line-width": ["interpolate", ["linear"], ["zoom"], 15, 0.2, 18, 1.2]
      }
    },
    {
      "id": "lake_elevation",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "spot_elevation",
      "minzoom": 12,
      "filter": ["all", ["==", "class", "lake_elevation"]],
      "layout": {
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-image": [
          "case",
          ["has", "lake_depth"],
          "arrow_line_blue",
          ["==", ["length", ["to-string", ["get", "ele"]]], 3],
          "line_blue_short",
          "line_blue_long"
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-size": 1,
        "symbol-placement": "point",
        "text-allow-overlap": false,
        "text-anchor": "center",
        "text-field": [
          "case",
          ["has", "lake_depth"],
          ["concat", ["get", "ele"], "\n\n", ["get", "lake_depth"]],
          ["get", "ele"]
        ],
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-justify": "center",
        "text-keep-upright": false,
        "text-line-height": 1,
        "text-offset": [
          "case",
          ["has", "lake_depth"],
          ["literal", [0.35, -0.5]],
          ["literal", [0, -0.5]]
        ],
        "text-optional": false,
        "text-pitch-alignment": "auto",
        "text-radial-offset": 0,
        "text-rotation-alignment": "auto",
        "text-size": ["interpolate", ["linear"], ["zoom"], 14, 10.5, 18, 13],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "#000000",
        "icon-opacity": 1,
        "text-color": "rgba(20, 136, 205, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 18, 2],
        "text-opacity": 1
      }
    },
    {
      "id": "bridge-l1_road_casing",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        ["==", "layer", 1],
        [
          "!in",
          "class",
          "rail",
          "ferry",
          "path",
          "transit",
          "cable_car",
          "gondola"
        ],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": [
          "interpolate",
          ["exponential", 1.7],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(170, 136, 30)",
            "rgb(80, 80, 80)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(139, 107, 63)",
            "rgb(90, 90, 90)"
          ]
        ],
        "line-offset": 0,
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 4],
            ["trunk"],
            4,
            3
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5, 5],
            ["primary"],
            5,
            ["secondary"],
            4.5,
            ["tertiary"],
            3.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 3.5, 5.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5.5, 5.5],
            ["primary"],
            5.5,
            ["secondary"],
            5,
            ["tertiary"],
            4,
            ["minor"],
            3,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 6.5, 8],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 8, 9],
            ["primary"],
            9,
            ["secondary"],
            8,
            ["tertiary"],
            7,
            ["minor"],
            5,
            ["track"],
            1.1,
            4
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 10, 12],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 12, 15],
            ["primary"],
            15,
            ["secondary"],
            13,
            ["tertiary"],
            11,
            ["minor"],
            9,
            ["track"],
            1.1,
            8
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            120,
            ["secondary"],
            115,
            ["tertiary"],
            110,
            ["minor"],
            100,
            ["track"],
            3,
            95
          ]
        ]
      }
    },
    {
      "id": "bridge-l1_fill",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        ["==", "layer", 1],
        ["!in", "class", "ferry", "cable_car", "gondola"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[8, 0.4], [14, 0.1]]},
        "line-color": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255,230,160)",
            ["rail", "path", "track"],
            [
              "match",
              ["get", "subclass"],
              "funicular",
              "rgba(243,243,246,0)",
              "rgb(243,243,246)"
            ],
            "rgb(255,255,255)",
            "rgb(243,243,246)",
            "rgb(255,255,255)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255, 224, 138)",
            ["rail", "path", "track"],
            [
              "match",
              ["get", "subclass"],
              "funicular",
              "rgba(243,243,246,0)",
              "rgb(243,243,246)"
            ],
            "rgb(255,255,255)"
          ]
        ],
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 2.75],
            ["trunk"],
            2.75,
            2
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 3.75],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 3.75, 3.75],
            ["primary"],
            3.75,
            ["secondary"],
            3.25,
            ["tertiary"],
            2.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.75, 4.25],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 4.25, 4.25],
            ["primary"],
            4.25,
            ["secondary"],
            3.75,
            ["tertiary"],
            2.75,
            ["minor"],
            1.75,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 5, 6.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 6.5, 7.5],
            ["primary"],
            7.5,
            ["secondary"],
            6.5,
            ["tertiary", "rail"],
            5.5,
            ["minor"],
            3.5,
            ["transit", "track", "path"],
            2.5,
            2.5
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 8.5, 10.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 10.5, 13.5],
            ["primary"],
            13.5,
            ["secondary"],
            11.5,
            ["tertiary", "rail"],
            9.5,
            ["minor"],
            7.5,
            ["transit", "track", "path"],
            6.5,
            6.5
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            117,
            ["secondary"],
            112,
            ["tertiary"],
            107,
            ["minor"],
            97,
            92
          ]
        ]
      }
    },
    {
      "id": "bridge-l1_road_track",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        ["==", "class", "track"],
        ["==", "layer", 1],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": ["interpolate", ["linear"], ["zoom"], 14, 3, 15, 0.4],
        "line-color": {
          "stops": [[9, "rgba(80, 80, 80, 1)"], [15, "rgba(90, 90, 90, 1)"]]
        },
        "line-offset": 0,
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          0,
          15,
          1.1,
          16,
          1.1,
          18,
          2,
          20,
          3
        ]
      }
    },
    {
      "id": "bridge-l1_road_path",
      "type": "line",
      "metadata": {
        "maputnik:comment": "cannot be in the same layer as road_casings because dasharray doesn't support expressions"
      },
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        ["in", "class", "path"],
        ["==", "layer", 1],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "bevel",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[15, 0.1], [20, 0.4]]},
        "line-color": "rgba(115, 115, 115, 1)",
        "line-dasharray": {
          "stops": [
            [14, [3, 1]],
            [15, [6, 2]],
            [16, [9, 3]],
            [17, [12, 4]],
            [18, [15, 5]]
          ]
        },
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          15,
          1.1,
          20,
          3
        ]
      }
    },
    {
      "id": "bridge-l1_public_transport",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        ["==", "layer", 1],
        ["in", "class", "rail", "transit"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {"line-cap": "round", "visibility": "visible"},
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          18,
          0.4
        ],
        "line-color": {
          "stops": [[7, "rgba(255, 50, 50, 1)"], [15, "rgba(255, 80, 80, 1)"]]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0,
          9,
          ["match", ["get", "class"], ["rail"], 1, 0],
          12,
          ["match", ["get", "class"], ["rail"], 1, 0],
          13,
          ["match", ["get", "class"], ["rail"], 1, 0],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge", "funicular"],
              ["match", ["get", "service"], ["yard", "siding"], 0, 1],
              0
            ],
            0
          ],
          14.5,
          1
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.2, 0.5],
              0.2
            ],
            0.2
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.1, 1],
              1
            ],
            ["cable_car"],
            1,
            0.75
          ],
          18,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 1.5, 2],
              2
            ],
            ["cable_car"],
            2,
            1.5
          ]
        ]
      }
    },
    {
      "id": "bridge-l2_road_casing",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        [">", "layer", 1],
        [
          "!in",
          "class",
          "rail",
          "ferry",
          "path",
          "transit",
          "cable_car",
          "gondola"
        ],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": 0.4,
        "line-color": [
          "interpolate",
          ["exponential", 1.7],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(170, 136, 30)",
            "rgb(80, 80, 80)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(139, 107, 63)",
            "rgb(90, 90, 90)"
          ]
        ],
        "line-offset": 0,
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 4],
            ["trunk"],
            4,
            3
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.5, 5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5, 5],
            ["primary"],
            5,
            ["secondary"],
            4.5,
            ["tertiary"],
            3.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 3.5, 5.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 5.5, 5.5],
            ["primary"],
            5.5,
            ["secondary"],
            5,
            ["tertiary"],
            4,
            ["minor"],
            3,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 6.5, 8],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 8, 9],
            ["primary"],
            9,
            ["secondary"],
            8,
            ["tertiary"],
            7,
            ["minor"],
            5,
            ["track"],
            1.1,
            4
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 10, 12],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 12, 15],
            ["primary"],
            15,
            ["secondary"],
            13,
            ["tertiary"],
            11,
            ["minor"],
            9,
            ["track"],
            1.1,
            8
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            120,
            ["secondary"],
            115,
            ["tertiary"],
            110,
            ["minor"],
            100,
            ["track"],
            3,
            95
          ]
        ]
      }
    },
    {
      "id": "bridge-l2_fill",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        [">", "layer", 1],
        ["!in", "class", "ferry", "cable_car", "gondola"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[8, 0.4], [14, 0.1]]},
        "line-color": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          9,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255,230,160)",
            ["rail", "path", "track"],
            [
              "match",
              ["get", "subclass"],
              "funicular",
              "rgba(243,243,246,0)",
              "rgb(243,243,246)"
            ],
            "rgb(255,255,255)",
            "rgb(243,243,246)",
            "rgb(255,255,255)"
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk"],
            "rgb(255, 224, 138)",
            ["rail", "path", "track"],
            [
              "match",
              ["get", "subclass"],
              "funicular",
              "rgba(243,243,246,0)",
              "rgb(243,243,246)"
            ],
            "rgb(255,255,255)"
          ]
        ],
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          10,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 2.75],
            ["trunk"],
            2.75,
            2
          ],
          12,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 1.5, 3.75],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 3.75, 3.75],
            ["primary"],
            3.75,
            ["secondary"],
            3.25,
            ["tertiary"],
            2.25,
            0
          ],
          13,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 2.75, 4.25],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 4.25, 4.25],
            ["primary"],
            4.25,
            ["secondary"],
            3.75,
            ["tertiary"],
            2.75,
            ["minor"],
            1.75,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 5, 6.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 6.5, 7.5],
            ["primary"],
            7.5,
            ["secondary"],
            6.5,
            ["tertiary", "rail"],
            5.5,
            ["minor"],
            3.5,
            ["transit", "track", "path"],
            2.5,
            2.5
          ],
          16,
          [
            "match",
            ["get", "class"],
            ["motorway"],
            ["match", ["get", "ramp"], 1, 8.5, 10.5],
            ["trunk"],
            ["match", ["get", "oneway"], 1, 10.5, 13.5],
            ["primary"],
            13.5,
            ["secondary"],
            11.5,
            ["tertiary", "rail"],
            9.5,
            ["minor"],
            7.5,
            ["transit", "track", "path"],
            6.5,
            6.5
          ],
          20,
          [
            "match",
            ["get", "class"],
            ["motorway", "trunk", "primary", "rail"],
            117,
            ["secondary"],
            112,
            ["tertiary"],
            107,
            ["minor"],
            97,
            92
          ]
        ]
      }
    },
    {
      "id": "bridge-l2_road_track",
      "type": "line",
      "metadata": {"maputnik:comment": "casing color for motorway is brown"},
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        [">", "layer", 1],
        ["==", "class", "track"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": ["interpolate", ["linear"], ["zoom"], 14, 3, 15, 0.4],
        "line-color": {
          "stops": [[9, "rgba(80, 80, 80, 1)"], [15, "rgba(90, 90, 90, 1)"]]
        },
        "line-offset": 0,
        "line-opacity": 1,
        "line-width": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          14,
          0,
          15,
          1.1,
          16,
          1.1,
          18,
          2,
          20,
          3
        ]
      }
    },
    {
      "id": "bridge-l2_road_path",
      "type": "line",
      "metadata": {
        "maputnik:comment": "cannot be in the same layer as road_casings because dasharray doesn't support expressions"
      },
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 14,
      "filter": [
        "all",
        [">", "layer", 1],
        ["in", "class", "path"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "bevel",
        "visibility": "visible"
      },
      "paint": {
        "line-blur": {"stops": [[15, 0.1], [20, 0.4]]},
        "line-color": "rgba(115, 115, 115, 1)",
        "line-dasharray": {
          "stops": [
            [14, [3, 1]],
            [15, [6, 2]],
            [16, [9, 3]],
            [17, [12, 4]],
            [18, [15, 5]]
          ]
        },
        "line-opacity": {"stops": [[14, 0], [15, 1]]},
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          15,
          1.1,
          20,
          3
        ]
      }
    },
    {
      "id": "bridge-l2_public_transport",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 10,
      "filter": [
        "all",
        [">", "layer", 1],
        ["in", "class", "rail", "transit", "cable_car", "gondola"],
        ["==", "$type", "LineString"],
        ["!=", "subclass", "covered_bridge"]
      ],
      "layout": {"line-cap": "round", "visibility": "visible"},
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          18,
          0.4
        ],
        "line-color": {
          "stops": [[7, "rgba(255, 50, 50, 1)"], [15, "rgba(255, 80, 80, 1)"]]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0,
          9,
          ["match", ["get", "class"], ["rail"], 1, 0],
          12,
          ["match", ["get", "class"], ["rail"], 1, 0],
          13,
          ["match", ["get", "class"], ["rail"], 1, 0],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge", "funicular"],
              ["match", ["get", "service"], ["yard", "siding"], 0, 1],
              0
            ],
            0
          ],
          14.5,
          1
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.2, 0.5],
              0.2
            ],
            0.2
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 0.1, 1],
              1
            ],
            ["cable_car"],
            1,
            0.75
          ],
          18,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 1.5, 2],
              2
            ],
            ["cable_car"],
            2,
            1.5
          ]
        ]
      }
    },
    {
      "id": "spot_elevation",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "spot_elevation",
      "minzoom": 14,
      "filter": [
        "all",
        [
          "!in",
          "class",
          "lake_elevation",
          "sinkhole",
          "sinkhole_rock",
          "sinkhole_scree",
          "sinkhole_water",
          "sinkhole_ice"
        ]
      ],
      "layout": {
        "icon-image": [
          "match",
          ["get", "class"],
          "spot_elevation",
          "dot_dark_grey_3",
          ""
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 30,
        "icon-rotate": 0,
        "icon-size": 1,
        "symbol-placement": "point",
        "text-allow-overlap": false,
        "text-anchor": "center",
        "text-field": ["get", "ele"],
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-keep-upright": false,
        "text-line-height": 0.9,
        "text-optional": false,
        "text-radial-offset": 0.3,
        "text-size": ["interpolate", ["linear"], ["zoom"], 14, 10.5, 18, 13],
        "text-variable-anchor": [
          "literal",
          ["bottom-left", "top-left", "bottom-right", "top-right"]
        ],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "#000000",
        "icon-opacity": 0.8,
        "text-color": "rgba(80, 80, 80, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          1.5,
          18,
          3
        ],
        "text-opacity": ["literal", 1]
      }
    },
    {
      "id": "water_name_point_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "water_name",
      "filter": ["all", ["==", "$type", "Point"], ["==", "class", "lake"]],
      "layout": {
        "symbol-placement": "point",
        "symbol-spacing": 350,
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": 0.05,
        "text-max-width": 1000,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "viewport",
        "text-size": ["interpolate", ["linear"], ["zoom"], 10, 10.5, 16, 16],
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(20, 136, 205, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.7)",
        "text-halo-width": 1
      }
    },
    {
      "id": "waterway_line_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "waterway",
      "minzoom": 9,
      "filter": [
        "all",
        ["==", "$type", "LineString"],
        ["!=", "intermittent", 1]
      ],
      "layout": {
        "symbol-placement": "line",
        "symbol-spacing": 650,
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0.1,
          12,
          ["match", ["get", "class"], ["river"], 0.4, 0.2]
        ],
        "text-max-width": 9999,
        "text-padding": 0,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          10,
          10.5,
          16,
          [
            "match",
            ["to-string", ["get", "width"]],
            ["9", "10"],
            18,
            ["7", "8"],
            17,
            ["5", "6"],
            16,
            "4",
            15,
            "3",
            14,
            "2",
            13,
            "1",
            12,
            ["match", ["get", "class"], ["river", "canal"], 18, 14]
          ]
        ]
      },
      "paint": {
        "text-color": [
          "match",
          ["get", "intermittent"],
          1,
          "rgb(64, 64, 64)",
          "rgb(20, 136, 205)"
        ],
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": 2
      }
    },
    {
      "id": "transportation_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "transportation_name",
      "minzoom": 13,
      "filter": ["all", ["==", "$type", "LineString"]],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line",
        "symbol-spacing": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          200,
          18,
          400
        ],
        "symbol-z-order": "auto",
        "text-anchor": "center",
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-keep-upright": true,
        "text-letter-spacing": 0.1,
        "text-max-angle": 40,
        "text-offset": [0, 0],
        "text-padding": 2,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          14,
          ["match", ["get", "class"], ["motorway", "trunk"], 11, 10.5],
          18,
          ["match", ["get", "class"], ["motorway", "trunk"], 18, 16]
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "text-color": [
          "match",
          ["get", "class"],
          ["rail", "transit", "cable_car", "gondola", "chair_lift"],
          "rgba(255, 50, 50, 1)",
          "rgba(60, 60, 70, 1)"
        ],
        "text-halo-blur": 0.2,
        "text-halo-color": [
          "match",
          ["get", "class"],
          [
            "rail",
            "transit",
            "cable_car",
            "gondola",
            "chair_lift",
            "motorway",
            "trunk"
          ],
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.8)"
        ],
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          [
            "match",
            ["get", "class"],
            ["rail", "transit", "cable_car", "gondola", "chair_lift"],
            1,
            1.5
          ],
          20,
          3
        ]
      }
    },
    {
      "id": "aerialway",
      "type": "line",
      "source": "swissmaptiles",
      "source-layer": "transportation",
      "minzoom": 12,
      "filter": ["all", ["in", "class", "cable_car", "gondola", "chair_lift"]],
      "layout": {"visibility": "visible"},
      "paint": {
        "line-blur": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          14,
          [
            "match",
            ["get", "class"],
            ["rail"],
            [
              "match",
              ["get", "subclass"],
              ["rail", "narrow_gauge"],
              ["match", ["get", "service"], ["yard", "siding"], 2.5, 0.6],
              0.6
            ],
            0.6
          ],
          18,
          0.4
        ],
        "line-color": {
          "stops": [[7, "rgba(255, 50, 50, 1)"], [15, "rgba(255, 80, 80, 1)"]]
        },
        "line-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          0,
          13,
          ["match", ["get", "class"], ["cable_car", "gondola"], 1, 0],
          14,
          [
            "match",
            ["get", "class"],
            ["cable_car", "gondola", "chair_lift"],
            1,
            0
          ]
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          8,
          0.2,
          14,
          ["match", ["get", "class"], ["cable_car"], 1, 0.75],
          18,
          ["match", ["get", "class"], ["cable_car"], 2, 1.5]
        ]
      }
    },
    {
      "id": "road_number",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "transportation_name",
      "minzoom": 11,
      "filter": [
        "all",
        ["in", "class", "motorway", "trunk", "primary"],
        ["has", "ref"],
        ["<=", "ref_length", 12],
        ["==", "$type", "LineString"]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-ignore-placement": false,
        "icon-image": "box_white_grey_casing_{ref_length}",
        "icon-keep-upright": false,
        "icon-offset": [0, -1.3],
        "icon-optional": false,
        "icon-padding": 2,
        "icon-pitch-alignment": "viewport",
        "icon-rotation-alignment": "viewport",
        "icon-size": 1,
        "icon-text-fit": "none",
        "symbol-avoid-edges": false,
        "symbol-placement": "line",
        "symbol-spacing": ["step", ["zoom"], 300, 10, 600, 14, 800],
        "symbol-z-order": "auto",
        "text-anchor": "center",
        "text-field": ["get", "ref"],
        "text-font": ["Frutiger Neue Condensed Bold"],
        "text-justify": "center",
        "text-keep-upright": true,
        "text-letter-spacing": 0,
        "text-optional": false,
        "text-pitch-alignment": "viewport",
        "text-rotation-alignment": "viewport",
        "text-size": 10.5,
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": 1,
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 0,
        "text-halo-color": "rgba(0, 0, 0, 0)",
        "text-halo-width": 0,
        "text-opacity": 1
      }
    },
    {
      "id": "area_name_glacier_point_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "area_name",
      "minzoom": 14,
      "filter": [
        "all",
        ["==", "$type", "Point"],
        ["in", "subclass", "glacier"]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "point",
        "symbol-spacing": {"stops": [[12, 250], [14, 550]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": 0.15,
        "text-max-width": 99999,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": ["interpolate", ["linear"], ["zoom"], 10, 11, 16, 20]
      },
      "paint": {
        "text-color": "rgba(55, 146, 201, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": 1
      }
    },
    {
      "id": "area_name_glacier_line_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "area_name",
      "minzoom": 12,
      "filter": [
        "all",
        ["==", "$type", "LineString"],
        ["in", "subclass", "glacier"]
      ],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "line-center",
        "symbol-spacing": {"stops": [[12, 250], [14, 550]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": {"stops": [[10, 0.15], [12, 0.3]]},
        "text-max-width": 99999,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": ["interpolate", ["linear"], ["zoom"], 10, 11, 16, 20]
      },
      "paint": {
        "text-color": "rgba(55, 146, 201, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": 1
      }
    },
    {
      "id": "poi_rank3",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "poi",
      "minzoom": 15,
      "filter": [
        "all",
        [
          "match",
          ["get", "class"],
          [
            "allottments",
            "attraction",
            "aerialway",
            "bus",
            "building",
            "cave",
            "entrance",
            "garden",
            "museum",
            "place_of_worship",
            "railway",
            "survey_point",
            "tower",
            "wastewater_plant",
            "waterfall"
          ],
          true,
          false
        ],
        [
          "match",
          ["get", "subclass"],
          [
            "allottments",
            "attraction",
            "bus_stop",
            "cave",
            "building",
            "garden",
            "halt",
            "museum",
            "observation",
            "station",
            "subway_entrance",
            "survey_point",
            "tram_stop",
            "wastewater_plant",
            "waterfall",
            ""
          ],
          true,
          false
        ]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-ignore-placement": false,
        "icon-image": [
          "match",
          ["get", "subclass"],
          ["tram_stop", "bus_stop", "halt", "station", "subway_entrance"],
          "dot_red",
          "waterfall",
          "waterfall_blue",
          "observation",
          "observation_tower_grey",
          "survey_point",
          "dot_dark_grey_4",
          ""
        ],
        "icon-optional": false,
        "icon-rotate": ["to-number", ["get", "direction"]],
        "icon-rotation-alignment": "map",
        "icon-size": 1,
        "text-anchor": [
          "match",
          ["get", "subclass"],
          [
            "halt",
            "tram_stop",
            "bus_stop",
            "station",
            "subway_entrance",
            "survey_point"
          ],
          "bottom-left",
          ["waterfall", "observation"],
          "left",
          "center"
        ],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-justify": "left",
        "text-max-width": 10,
        "text-offset": [
          "match",
          ["get", "subclass"],
          "observation",
          ["literal", [0.5, 0.2]],
          "waterfall",
          ["literal", [0.5, 0.1]],
          ["literal", [0.4, 0.1]]
        ],
        "text-optional": false,
        "text-padding": 2,
        "text-size": ["interpolate", ["linear"], ["zoom"], 14, 12, 18, 18],
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": ["case", ["has", "name"], 1, 0],
        "text-color": [
          "match",
          ["get", "subclass"],
          ["halt", "tram_stop", "bus_stop", "station", "subway_entrance"],
          "rgba(255, 50, 50, 1)",
          "waterfall",
          "rgba(20, 136, 205, 1)",
          "rgba(48, 48, 48, 1)"
        ],
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 17, 2],
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          15,
          ["match", ["get", "class"], "college", 0, 1],
          16,
          1
        ]
      }
    },
    {
      "id": "poi_rank2",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "poi",
      "minzoom": 13,
      "filter": [
        "any",
        [
          "match",
          ["get", "class"],
          [
            "cemetery",
            "tower",
            "dam",
            "ferry_terminal",
            "funicular",
            "attraction",
            "fuel",
            "golf",
            "golf_course",
            "horse_racing",
            "military",
            "monastery",
            "park",
            "pitch",
            "prison",
            "ruins",
            "sports_centre",
            "stadium",
            "swimming_pool",
            "power",
            "weir",
            "zoo"
          ],
          true,
          false
        ],
        ["match", ["get", "subclass"], "camp_site", true, false]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-ignore-placement": false,
        "icon-image": [
          "step",
          ["zoom"],
          [
            "match",
            ["get", "subclass"],
            "church_tower",
            "dot_circle_dark_grey_8",
            ["golf_course", "golf"],
            "golf_grey",
            "ferry_terminal",
            "dot_blue",
            "funicular_stop",
            "dot_red",
            "wind_turbine",
            "windturbine_grey",
            "communications_tower",
            "communications_tower_grey",
            ""
          ],
          15,
          [
            "match",
            ["get", "subclass"],
            ["camp_site", "caravan_site"],
            "camping_grey",
            "church_tower",
            "dot_circle_dark_grey_10",
            ["golf_course", "golf"],
            "golf_grey",
            "ferry_terminal",
            "dot_blue",
            "funicular_stop",
            "dot_red",
            "wind_turbine",
            "windturbine_grey",
            "communications_tower",
            "communications_tower_grey",
            ""
          ],
          16,
          [
            "match",
            ["get", "subclass"],
            ["camp_site", "caravan_site"],
            "camping_grey",
            "church_tower",
            "dot_circle_dark_grey_12",
            ["golf_course", "golf"],
            "golf_grey",
            "ferry_terminal",
            "dot_blue",
            "funicular_stop",
            "dot_red",
            "hospital",
            "hospital_grey",
            "wind_turbine",
            "windturbine_grey",
            "communications_tower",
            "communications_tower_grey",
            ""
          ]
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-size": 1,
        "text-anchor": [
          "match",
          ["get", "class"],
          ["monastery"],
          "left",
          [
            "match",
            ["get", "subclass"],
            [
              "caravan_site",
              "camp_site",
              "church_tower",
              "golf_course",
              "golf",
              "hospital",
              "communications_tower"
            ],
            "left",
            ["ferry_terminal", "funicular_stop"],
            "bottom-left",
            "center"
          ]
        ],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-justify": "left",
        "text-letter-spacing": ["match", ["get", "class"], ["park"], 0.05, 0],
        "text-max-width": 10,
        "text-offset": [
          "match",
          ["get", "subclass"],
          ["ferry_terminal", "funicular_stop"],
          ["literal", [0.4, 0.1]],
          ["literal", [0.8, 0.1]]
        ],
        "text-optional": true,
        "text-size": ["interpolate", ["linear"], ["zoom"], 14, 12, 18, 18],
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": [
          "step",
          ["zoom"],
          0,
          13,
          ["match", ["get", "subclass"], "wind_turbine", 0, 0],
          14,
          [
            "match",
            ["get", "class"],
            ["place_of_worship"],
            1,
            [
              "match",
              ["get", "subclass"],
              [
                "wind_turbine",
                "caravan_site",
                "camp_site",
                "ferry_terminal",
                "funicular_stop",
                "golf_course",
                "golf",
                "cemetery",
                "stadium",
                "park"
              ],
              1,
              0
            ]
          ],
          15,
          1
        ],
        "text-color": [
          "match",
          ["get", "subclass"],
          "ferry_terminal",
          "rgba(20, 136, 205, 1)",
          "funicular_stop",
          "rgba(255, 50, 50, 1)",
          "weir",
          "rgba(20, 136, 205, 1)",
          "rgba(48, 48, 48, 1)"
        ],
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 17, 2],
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          14,
          [
            "match",
            ["get", "class"],
            [
              "ferry_terminal",
              "funicular",
              "golf",
              "golf_course",
              "cemetery",
              "ruins",
              "stadium",
              "park",
              "pitch"
            ],
            1,
            0
          ],
          15,
          [
            "match",
            ["get", "class"],
            [
              "campsite",
              "ferry_terminal",
              "funicular",
              "golf",
              "golf_course",
              "cemetery",
              "stadium",
              "park",
              "pitch",
              "place_of_worship",
              "sports_centre",
              "zoo"
            ],
            1,
            0
          ],
          16,
          [
            "match",
            ["get", "class"],
            [
              "campsite",
              "ferry_terminal",
              "funicular",
              "golf",
              "golf_course",
              "cemetery",
              "stadium",
              "park",
              "pitch",
              "place_of_worship",
              "sports_centre",
              "zoo"
            ],
            1,
            0
          ],
          17,
          1
        ]
      }
    },
    {
      "id": "peaks_other",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 15,
      "maxzoom": 24,
      "filter": [
        "match",
        ["get", "class"],
        ["rocky_knoll", "saddle"],
        true,
        false
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-anchor": "center",
        "icon-ignore-placement": false,
        "icon-image": "dot_dark_grey_3",
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 0,
        "icon-pitch-alignment": "auto",
        "icon-rotation-alignment": "auto",
        "icon-size": 1,
        "symbol-placement": "point",
        "symbol-z-order": ["literal", "auto"],
        "text-allow-overlap": false,
        "text-anchor": "center",
        "text-field": [
          "format",
          ["get", "name:latin"],
          {},
          "\n",
          {},
          ["get", "ele"],
          {"font-scale": 0.75}
        ],
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-line-height": 1.15,
        "text-max-width": 8,
        "text-offset": [0, 0],
        "text-optional": false,
        "text-padding": 2,
        "text-radial-offset": 0.3,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            14,
            ["<=", 3000, ["get", "ele"]],
            13,
            ["<=", 2000, ["get", "ele"]],
            12,
            11
          ],
          18,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            23,
            ["<=", 3000, ["get", "ele"]],
            22,
            ["<=", 2000, ["get", "ele"]],
            21,
            20
          ]
        ],
        "text-variable-anchor": [
          "literal",
          ["bottom-left", "top-left", "bottom-right", "top-right"]
        ],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "rgba(0, 0, 0, 1)",
        "icon-halo-blur": 0,
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-halo-width": 0,
        "icon-opacity": 1,
        "text-color": "rgba(48, 48, 48, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          1.5,
          18,
          3
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "peaks_rank3+",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 14,
      "maxzoom": 24,
      "filter": [
        "all",
        [">=", "rank", 3],
        ["!in", "class", "rocky_knoll", "saddle", "mountain_pass"]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-anchor": "center",
        "icon-ignore-placement": false,
        "icon-image": [
          "step",
          ["zoom"],
          "",
          10,
          "dot_dark_grey_4",
          14,
          "dot_dark_grey_6",
          18,
          "dot_dark_grey_8"
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 0,
        "icon-pitch-alignment": "auto",
        "icon-rotation-alignment": "auto",
        "icon-size": 1,
        "symbol-placement": "point",
        "symbol-z-order": ["literal", "auto"],
        "text-allow-overlap": false,
        "text-anchor": "bottom-left",
        "text-field": [
          "format",
          ["get", "name:latin"],
          {},
          "\n",
          {},
          ["get", "ele"],
          {"font-scale": 0.75}
        ],
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-line-height": 1.15,
        "text-max-width": 8,
        "text-offset": [0, 0],
        "text-optional": false,
        "text-padding": 2,
        "text-radial-offset": 0.3,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            14,
            ["<=", 3000, ["get", "ele"]],
            13,
            ["<=", 2000, ["get", "ele"]],
            12,
            11
          ],
          18,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            23,
            ["<=", 3000, ["get", "ele"]],
            22,
            ["<=", 2000, ["get", "ele"]],
            21,
            20
          ]
        ],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "rgba(0, 0, 0, 1)",
        "icon-halo-blur": 0,
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-halo-width": 0,
        "icon-opacity": 1,
        "text-color": "rgba(48, 48, 48, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          1.5,
          18,
          3
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "peaks_rank2",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 11,
      "maxzoom": 24,
      "filter": [
        "all",
        ["==", "rank", 2],
        ["!in", "class", "rocky_knoll", "saddle", "mountain_pass"]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-anchor": "center",
        "icon-ignore-placement": false,
        "icon-image": [
          "step",
          ["zoom"],
          "",
          10,
          "dot_dark_grey_4",
          14,
          "dot_dark_grey_6",
          18,
          "dot_dark_grey_8"
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 0,
        "icon-pitch-alignment": "auto",
        "icon-rotation-alignment": "auto",
        "icon-size": 1,
        "symbol-placement": "point",
        "symbol-z-order": ["literal", "auto"],
        "text-allow-overlap": false,
        "text-anchor": "bottom-left",
        "text-field": [
          "format",
          ["get", "name:latin"],
          {},
          "\n",
          {},
          ["get", "ele"],
          {"font-scale": 0.75}
        ],
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-line-height": 1.15,
        "text-max-width": 8,
        "text-offset": [0, 0],
        "text-optional": false,
        "text-padding": 2,
        "text-radial-offset": 0.3,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            14,
            ["<=", 3000, ["get", "ele"]],
            13,
            ["<=", 2000, ["get", "ele"]],
            12,
            11
          ],
          18,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            23,
            ["<=", 3000, ["get", "ele"]],
            22,
            ["<=", 2000, ["get", "ele"]],
            21,
            20
          ]
        ],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "rgba(0, 0, 0, 1)",
        "icon-halo-blur": 0,
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-halo-width": 0,
        "icon-opacity": 1,
        "text-color": "rgba(48, 48, 48, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          12,
          1.5,
          18,
          3
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "peaks_rank1",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 9,
      "maxzoom": 24,
      "filter": [
        "all",
        ["==", "rank", 1],
        ["!in", "class", "rocky_knoll", "saddle", "mountain_pass"]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "icon-anchor": "center",
        "icon-ignore-placement": false,
        "icon-image": [
          "step",
          ["zoom"],
          "dot_dark_grey_4",
          14,
          "dot_dark_grey_6",
          18,
          "dot_dark_grey_8"
        ],
        "icon-offset": [0, 0],
        "icon-optional": false,
        "icon-padding": 2,
        "icon-pitch-alignment": "auto",
        "icon-rotation-alignment": "auto",
        "icon-size": 1,
        "symbol-placement": "point",
        "symbol-z-order": ["literal", "auto"],
        "text-allow-overlap": false,
        "text-anchor": "bottom-left",
        "text-field": [
          "format",
          ["get", "name:latin"],
          {},
          "\n",
          {},
          ["get", "ele"],
          {"font-scale": 0.75}
        ],
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-line-height": 1.15,
        "text-max-width": 8,
        "text-optional": false,
        "text-padding": 10,
        "text-radial-offset": 0.3,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            14,
            ["<=", 3000, ["get", "ele"]],
            13,
            ["<=", 2000, ["get", "ele"]],
            12,
            11
          ],
          18,
          [
            "case",
            ["<=", 4000, ["get", "ele"]],
            23,
            ["<=", 3000, ["get", "ele"]],
            22,
            ["<=", 2000, ["get", "ele"]],
            21,
            20
          ]
        ],
        "visibility": "visible"
      },
      "paint": {
        "icon-color": "rgba(0, 0, 0, 1)",
        "icon-halo-blur": 0,
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-halo-width": 0,
        "icon-opacity": [
          "step",
          ["zoom"],
          0,
          9,
          ["case", ["<=", 3200, ["get", "ele"]], 1, 0],
          10,
          1
        ],
        "text-color": "rgba(48, 48, 48, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          1.5,
          18,
          3
        ],
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          9,
          ["case", ["<=", 3200, ["get", "ele"]], 1, 0],
          10,
          1
        ]
      }
    },
    {
      "id": "swissimage",
      "type": "raster",
      "source": "swissimage",
      "paint": {},
      "layout": {
        "visibility": "none"
      }
    },
    {
      "id": "place_other",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 11,
      "maxzoom": 24,
      "filter": [
        "all",
        [
          "!in",
          "class",
          "continent",
          "country",
          "state",
          "city",
          "town",
          "village",
          "hamlet",
          "isolated_dwelling"
        ]
      ],
      "layout": {
        "icon-optional": false,
        "icon-size": 1,
        "symbol-sort-key": ["to-number", ["get", "rank"]],
        "symbol-z-order": ["literal", "auto"],
        "text-anchor": "center",
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Medium"],
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-max-width": 10,
        "text-offset": ["literal", [0.3, 0.2]],
        "text-padding": 20,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          4,
          6,
          16,
          20
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": [
          "step",
          ["zoom"],
          0,
          11,
          ["case", [">", 18, ["get", "rank"]], 1, 0],
          13,
          1,
          14,
          0
        ],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["cubic-bezier", 0, 0.75, 0.25, 1],
          ["zoom"],
          12,
          0,
          16,
          2
        ],
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          11,
          ["case", [">", 18, ["get", "rank"]], 1, 0],
          13,
          1
        ]
      }
    },
    {
      "id": "poi_rank1",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "poi",
      "minzoom": 14,
      "filter": [
        "all",
        ["in", "subclass", "castle", "station"],
        ["in", "class", "castle", "railway"]
      ],
      "layout": {
        "icon-image": [
          "match",
          ["get", "subclass"],
          "station",
          "square_red",
          "castle",
          "castle_grey",
          ""
        ],
        "icon-size": 1,
        "symbol-avoid-edges": true,
        "text-anchor": [
          "match",
          ["get", "subclass"],
          ["castle"],
          "left",
          "station",
          "bottom-left",
          "center"
        ],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-justify": "left",
        "text-max-width": 10,
        "text-offset": [
          "match",
          ["get", "subclass"],
          "station",
          ["literal", [0.5, 0.15]],
          ["literal", [0.8, 0.15]]
        ],
        "text-optional": false,
        "text-padding": 10,
        "text-size": ["interpolate", ["linear"], ["zoom"], 13, 13, 18, 20],
        "visibility": "visible"
      },
      "paint": {
        "text-color": [
          "match",
          ["get", "subclass"],
          "station",
          "rgba(255, 50, 50, 1)",
          "rgba(48, 48, 48, 1)"
        ],
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 17, 2],
        "text-opacity": 1
      }
    },
    {
      "id": "place_hamlet_isolated_dwelling",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 11,
      "maxzoom": 24,
      "filter": ["all", ["in", "class", "hamlet", "isolated_dwelling"]],
      "layout": {
        "icon-image": [
          "step",
          ["zoom"],
          "circle_dark_grey_4",
          12,
          "circle_dark_grey_6"
        ],
        "icon-optional": false,
        "icon-size": 1,
        "symbol-sort-key": ["to-number", ["get", "rank"]],
        "symbol-z-order": ["literal", "auto"],
        "text-anchor": ["literal", "bottom-left"],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Medium"],
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-max-width": 10,
        "text-offset": ["literal", [0.3, 0.2]],
        "text-padding": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          2,
          13,
          2,
          14,
          0
        ],
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          4,
          6,
          16,
          18
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": [
          "step",
          ["zoom"],
          0,
          11,
          ["case", [">", 30, ["get", "rank"]], 1, 0],
          13,
          ["case", [">", 31, ["get", "rank"]], 1, 0],
          14,
          0
        ],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["cubic-bezier", 0, 0.75, 0.25, 1],
          ["zoom"],
          12,
          0,
          16,
          2
        ],
        "text-opacity": [
          "step",
          ["zoom"],
          0,
          11,
          ["case", [">", 30, ["get", "rank"]], 1, 0],
          13,
          ["case", [">", 31, ["get", "rank"]], 1, 0],
          14,
          1
        ]
      }
    },
    {
      "id": "place_village",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 7,
      "maxzoom": 18,
      "filter": ["all", ["==", "class", "village"]],
      "layout": {
        "icon-image": [
          "step",
          ["zoom"],
          "circle_dark_grey_4",
          6,
          "circle_dark_grey_4",
          8,
          "circle_dark_grey_6",
          10,
          "circle_dark_grey_8",
          12,
          "circle_dark_grey_10"
        ],
        "icon-optional": false,
        "icon-size": 1,
        "symbol-sort-key": ["to-number", ["get", "rank"]],
        "symbol-z-order": ["literal", "auto"],
        "text-anchor": ["literal", "bottom-left"],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Medium"],
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-max-width": 10,
        "text-offset": ["literal", [0.4, 0.2]],
        "text-optional": false,
        "text-padding": 20,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          4,
          6,
          10,
          16,
          16,
          28
        ],
        "text-transform": "none",
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": ["step", ["zoom"], 1, 13, 0],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["cubic-bezier", 0, 0.75, 0.25, 1],
          ["zoom"],
          12,
          0,
          16,
          2
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "aerodrome_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "aerodrome_label",
      "minzoom": 11,
      "filter": ["all", ["!=", "class", "helipad"]],
      "layout": {
        "icon-anchor": "center",
        "icon-image": [
          "match",
          ["get", "class"],
          "international",
          "airplane_large_grey",
          "regional",
          "airplane_medium_grey",
          "other",
          "airplane_small_grey",
          "helipad",
          "helicopter_grey",
          ""
        ],
        "icon-size": ["interpolate", ["linear"], ["zoom"], 11, 0.7, 14, 1],
        "text-anchor": "left",
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-justify": "center",
        "text-offset": [
          "match",
          ["get", "class"],
          "helipad",
          ["literal", [0.9, 0.2]],
          ["literal", [0.9, 0]]
        ],
        "text-padding": 2,
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          11,
          ["match", ["get", "class"], "international", 16, "regional", 16, 12],
          14,
          ["match", ["get", "class"], "international", 20, "regional", 18, 16]
        ],
        "text-transform": "none"
      },
      "paint": {
        "icon-opacity": ["literal", 1],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.8)",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 17, 2],
        "text-opacity": 1
      }
    },
    {
      "id": "water_name_line_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "water_name",
      "filter": [
        "all",
        ["==", "$type", "LineString"],
        ["!=", "class", "lake_part"]
      ],
      "layout": {
        "symbol-avoid-edges": false,
        "symbol-placement": "line-center",
        "symbol-spacing": {"stops": [[10, 250], [13, 950]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Italic"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": 0.15,
        "text-max-width": 99999,
        "text-padding": 2,
        "text-pitch-alignment": "map",
        "text-rotation-alignment": "map",
        "text-size": ["interpolate", ["linear"], ["zoom"], 10, 14, 16, 24]
      },
      "paint": {
        "text-color": "rgba(20, 136, 205, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": 1
      }
    },
    {
      "id": "place_town",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 6,
      "maxzoom": 16,
      "filter": ["all", ["==", "class", "town"]],
      "layout": {
        "icon-image": [
          "step",
          ["zoom"],
          "circle_dark_grey_4",
          6,
          "circle_dark_grey_6",
          8,
          "circle_dark_grey_8",
          10,
          "circle_dark_grey_10",
          12,
          "circle_dark_grey_12"
        ],
        "icon-optional": false,
        "icon-size": 1,
        "symbol-placement": "point",
        "symbol-sort-key": ["to-number", ["get", "rank"]],
        "symbol-z-order": ["literal", "auto"],
        "text-anchor": ["literal", "bottom-left"],
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Bold"],
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-max-width": 10,
        "text-offset": ["literal", [0.35, 0.1]],
        "text-padding": 10,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          4,
          8,
          16,
          32
        ],
        "text-transform": "uppercase",
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": ["step", ["zoom"], 1, 12, 0],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["cubic-bezier", 0, 0.75, 0.25, 1],
          ["zoom"],
          12,
          0,
          16,
          3
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "park_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "park",
      "minzoom": 8,
      "filter": [
        "all",
        ["in", "class", "national_park"],
        ["==", "$type", "Point"]
      ],
      "layout": {
        "symbol-z-order": ["literal", "auto"],
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Regular"],
        "text-padding": ["interpolate", ["linear"], ["zoom"], 10, 2, 14, 10],
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          8,
          14,
          16,
          28
        ]
      },
      "paint": {
        "text-color": "rgba(70, 130, 25, 0.9)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(255, 255, 255, 0.8)",
        "text-halo-width": 1
      }
    },
    {
      "id": "area_name_massif_label",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "area_name",
      "minzoom": 11,
      "filter": ["all", ["==", "$type", "Point"], ["in", "subclass", "massif"]],
      "layout": {
        "symbol-avoid-edges": true,
        "symbol-placement": "point",
        "symbol-spacing": {"stops": [[12, 250], [14, 550]]},
        "symbol-z-order": "auto",
        "text-allow-overlap": false,
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Regular"],
        "text-ignore-placement": false,
        "text-keep-upright": true,
        "text-letter-spacing": 0.07,
        "text-max-width": 99999,
        "text-pitch-alignment": "viewport",
        "text-rotation-alignment": "viewport",
        "text-size": ["interpolate", ["linear"], ["zoom"], 10, 14, 16, 23]
      },
      "paint": {
        "text-color": "rgba(48, 48, 48, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": 1
      }
    },
    {
      "id": "place_city",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 2,
      "maxzoom": 14,
      "filter": ["all", ["==", "class", "city"]],
      "layout": {
        "icon-image": [
          "step",
          ["zoom"],
          "dot_circle_dark_grey_6",
          6,
          "dot_circle_dark_grey_8",
          8,
          "circle_circle_dark_grey_10",
          10,
          "circle_circle_dark_grey_12",
          12,
          "circle_circle_dark_grey_12"
        ],
        "icon-optional": false,
        "icon-size": 1,
        "symbol-sort-key": ["to-number", ["get", "rank"]],
        "symbol-z-order": ["literal", "auto"],
        "text-anchor": "bottom-left",
        "text-field": ["get", "name:latin"],
        "text-font": ["Frutiger Neue Condensed Bold"],
        "text-justify": "auto",
        "text-letter-spacing": 0.025,
        "text-max-width": 10,
        "text-offset": ["literal", [0.35, 0.1]],
        "text-padding": 10,
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0.1, 0.7, 1],
          ["zoom"],
          1,
          11,
          4,
          12,
          16,
          48
        ],
        "text-transform": "uppercase",
        "visibility": "visible"
      },
      "paint": {
        "icon-opacity": ["step", ["zoom"], 1, 11, 0],
        "text-color": "rgba(64, 64, 64, 1)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(242, 242, 242, 0.6)",
        "text-halo-width": [
          "interpolate",
          ["cubic-bezier", 0, 0.75, 0.25, 1],
          ["zoom"],
          12,
          0,
          16,
          3
        ],
        "text-opacity": 1
      }
    },
    {
      "id": "place_country",
      "type": "symbol",
      "source": "swissmaptiles",
      "source-layer": "place",
      "minzoom": 0,
      "maxzoom": 7,
      "filter": ["all", ["in", "class", "country"], ["in", "iso_a2", "CH"]],
      "layout": {
        "text-field": "{name:latin}",
        "text-font": ["Frutiger Neue Condensed Regular"],
        "text-letter-spacing": 0.12,
        "text-max-width": 10,
        "text-size": ["interpolate", ["linear"], ["zoom"], 1, 12, 6, 16],
        "text-transform": "uppercase",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(145, 70, 145, 0.9)",
        "text-halo-blur": 1,
        "text-halo-color": "rgba(255, 255, 255, 0.8)",
        "text-halo-width": 1,
        "text-opacity": ["interpolate", ["linear"], ["zoom"], 6, 1, 7, 0]
      }
    }
  ],
  "id": "b484873e-ac8c-45d6-a562-5402ab0f4985"
}
