import DegreePosition from '@/classes/DegreePosition';
import * as maplibregl from 'maplibre-gl'
import { Commit, Dispatch } from 'vuex';
import sources from '@/data/sources.json';
import customBasiskarteStyle from '@/data/customBasisKarte';
import { MVT_SERVER } from '@/constants'
import { getTodayWeek, render2DigitNumber } from '@/classes/Utils';
import WorkType from '@/classes/WorkType';
import Synthesis from '@/classes/Synthesis';
import Constraint from '@/classes/Constraint/Constraint';
import { ConstraintWeekState } from '@/components/SideBar/ConstraintsViewer/PeriodSelector/ConstraintWeek';

export const VIEWER_MODULE_NAME = "viewer"
export const VIEWER_ACTION_INITIALIZE = `${VIEWER_MODULE_NAME}/initialize`
export const VIEWER_ACTION_ADD_MARKER = `${VIEWER_MODULE_NAME}/addMarker`
export const VIEWER_ACTION_REMOVE_MARKER = `${VIEWER_MODULE_NAME}/removeMarker`
export const VIEWER_ACTION_CHANGE_STYLE = `${VIEWER_MODULE_NAME}/changeStyle`
export const VIEWER_GETTER_VIEWER_INSTANCE = `viewerInstance`
export const VIEWER_GETTER_MOUSE_GEO_POSITION = 'mouseGeoPosition'
export const VIEWER_GETTER_VISIBLE_LAYERS_ID = 'visibleLayersId'
export const VIEWER_ACTION_SHOW_LAYER = `${VIEWER_MODULE_NAME}/showLayer`
export const VIEWER_ACTION_HIDE_LAYER = `${VIEWER_MODULE_NAME}/hideLayer`
export const VIEWER_GETTER_AREA_MODE = `areaMode`
export const VIEWER_GETTER_VISUALIZATION_MODE = `visualizationMode`
export const VIEWER_GETTER_VISIBLE_CONSTRAINTS_IDS = 'visibleConstraintsIds'
export const VIEWER_ACTION_SET_VISUALIZATION_MODE = `${VIEWER_MODULE_NAME}/setVisualizationMode`
export const VIEWER_ACTION_SET_AREA_MODE = `${VIEWER_MODULE_NAME}/setAreaMode`
export const VIEWER_GETTER_WEEKNUM = `weekNum`
export const VIEWER_ACTION_SET_WEEKNUM = `${VIEWER_MODULE_NAME}/setWeekNum`
export const VIEWER_GETTER_WORKTYPE = `workType`
export const VIEWER_ACTION_SET_WORKTYPE = `${VIEWER_MODULE_NAME}/setWorkType`
export const VIEWER_ACTION_SET_SYNTHESES = `${VIEWER_MODULE_NAME}/setSyntheses`
export const VIEWER_GETTER_SYNTHESES = 'syntheses'
export const VIEWER_GETTER_CONSTRAINTS = 'constraints'
export const VIEWER_ACTION_SET_CONSTRAINTS = `${VIEWER_MODULE_NAME}/setConstraints`
export const VIEWER_GETTER_LOADING = 'loading'
export const VIEWER_ACTION_SET_MARKER_POPUP_STATE = `${VIEWER_MODULE_NAME}/setMarkerPopupState`
export const VIEWER_GETTER_MARKER_GEO_POSITION = `markerGeoPosition`

const BILAN_CONTRAINTE_LAYER_ID = 'bilanscontraintes'
let viewerInstance: maplibregl.Map
const popup: maplibregl.Popup = new maplibregl.Popup({ closeOnClick: false })
const marker: maplibregl.Marker = new maplibregl.Marker().setPopup(popup)
const layers: MapBoxLayer[] = []

const showPopup = (): void => {
  if (!popup.isOpen()) {
    marker.togglePopup()
  }
}

const hidePopup = (): void => {
  if (popup.isOpen()) {
    marker.togglePopup()
  }
}

export enum AreaMode {
  ZONE,
  POSITION,
  UNDEFINED
}

export enum VisualizationMode {
  SYNTHESIS,
  INDIVIDUAL,
  UNDEFINED
}

export interface ViewerState {
  mouseGeoPosition: DegreePosition
  markerGeoPosition: DegreePosition
  visibleLayersId: string[]
  visualizationMode: VisualizationMode
  areaMode: AreaMode
  visibleConstraintsIds: string[]
  weekNum: number
  workType: WorkType
  syntheses: Synthesis[]
  constraints: Constraint[]
  loading: boolean
  mousedown: boolean
  movingmap: boolean
}

type MapBoxLayer = Record<string, unknown>

const getScreenMapboxBoundingBox = (): [maplibregl.PointLike, maplibregl.PointLike] => {
  const screen = window.screen
  return [new maplibregl.Point(0,0), new maplibregl.Point(screen.width, screen.height)] as [maplibregl.PointLike, maplibregl.PointLike]
}

const getConstraintLayers = (): MapBoxLayer[] => {
  return layers.filter(layer => (layer).sourceLayer === 'contraintes_buffer')
}

const getRenderedFeatures = (position: maplibregl.Point | [maplibregl.PointLike, maplibregl.PointLike], filter: Record<string, unknown> = {}) => {
  return viewerInstance.queryRenderedFeatures(position, filter).filter(feature => feature.source !== 'swissmaptiles')
}

// Recherche l'ensemble des contraintes visibles sur une position ou sur l'emprise de l'écran
const getVisibleConstraintsIds = (areaMode: AreaMode, visualizationMode: VisualizationMode, syntheses: Synthesis[]): string[] => {
  let visibleConstraintIds: string[] = []
  const constraintLayerIds = getConstraintLayers().map(constraintLayer => constraintLayer.id as string)
  const screenBB = getScreenMapboxBoundingBox()

  if (visualizationMode === VisualizationMode.INDIVIDUAL) {
    if (areaMode === AreaMode.ZONE) {
      visibleConstraintIds = getRenderedFeatures(screenBB, { layers: constraintLayerIds }).map(feature => feature.properties?.id)
    } else {
      const markerScreenPosition = viewerInstance.project(marker.getLngLat())
      visibleConstraintIds = getRenderedFeatures(markerScreenPosition, { layers: constraintLayerIds }).map(feature => feature.properties?.id)
    }
  } else if (visualizationMode === VisualizationMode.SYNTHESIS) {
    let visibleSyntheses: Synthesis[] = []
    let visibleSynthesesId: string[] = []
    // Récupérer les bilans de contraintes la position/zone recherchée
    if (areaMode === AreaMode.ZONE) {
        // Récupérer les id des bilans de contraintes dans la bounding box de l'écran
      visibleSynthesesId = getRenderedFeatures(screenBB, { layers: [BILAN_CONTRAINTE_LAYER_ID] }).map(feature => feature.properties?.id)
    } else if (areaMode === AreaMode.POSITION) {
        // Récupérer les id des bilans de contraintes positionnée sous le marqueur
      const markerScreenPosition = viewerInstance.project(marker.getLngLat())
      visibleSynthesesId = getRenderedFeatures(markerScreenPosition, { layers: [BILAN_CONTRAINTE_LAYER_ID] }).map(feature => feature.properties?.id)
    }
    // Récupérer les bilans de contraintes associés
    visibleSyntheses = visibleSynthesesId.map(id => {
      const correspondingSynthesis = syntheses.find(el => el.id === parseInt(id))
      if (!correspondingSynthesis || correspondingSynthesis === undefined) {
        console.error(`Le bilan de contrainte ayant pour id ${id} est introuvable dans l'ensemble des bilans de contraintes`)
        return { id: -1, constraintsIds: [] as number[] }
      } else {
        return correspondingSynthesis
      }
    })
    // Récupérer les id des contraintes associées
    visibleConstraintIds = visibleSyntheses.map(synthesis => synthesis.constraintsIds.map(el => el.toString())).flat()
  }

  // Supprimer les doublons
  return [...new Set(visibleConstraintIds)]
}

/**
 * Mets à jour le style des éléments de bilan en fonction de la semaine selectionnée
 * @param syntheses ensemble faisant le lien entre les id des bilans et des contraintes associées
 * @param weekNum numéro de semaine
 */
const renderSynthesis = (syntheses: Synthesis[], constraints: Constraint[], workType: WorkType, weekNum: number): void => {
  // Récuperer tous les éléments de la couche bilans contraintes qui ont été rendu
  const renderedSyntheses = viewerInstance.queryRenderedFeatures(getScreenMapboxBoundingBox(), { layers: ['bilanscontraintes'] })
  delay((): void => {
    // FIXME : on peut rendre les features uniques par ids car ici il y a redondance de features
    renderedSyntheses.forEach(synthesisFeature => {
      // Changer le style du bilan
      const synthesisId = synthesisFeature.properties?.id
      if (!synthesisFeature) {
        console.error(`Un élément de bilan de contrainte mapbox ne possède pas de bilan`)
        return
      }
      const correspondingSynthesis = syntheses.find(el => el.id === synthesisId)
      if (!correspondingSynthesis) {
        console.error(`Le bilan ayant pour id ${synthesisId} est introuvable`)
        return
      }
      // Déterminer si une contrainte est défavorable sur la semaine donnée
      let oneConstraintUnfavorable = false
      for (const constraintId of correspondingSynthesis.constraintsIds) {
        // Récuperer la contrainte
        const constraint = constraints.find(el => el.id === constraintId)
        if (!constraint) {
          console.error(`La contrainte ayant pour id ${constraintId} est introuvable`)
          return
        }
        // Si le type de travaux est associé à la contrainte 
        if (constraint.associatedToWorkType(workType)) {
          // Tester si la contrainte est favorable pendant la semaine selectionnée
          oneConstraintUnfavorable = !constraint.isFavorableDuringWeek(weekNum)
          if (oneConstraintUnfavorable) break
        }
      }
      viewerInstance.setFeatureState(
        { source: 'bilanscontraintes-src', sourceLayer: 'bilanscontraintes', id: synthesisFeature.id },
        { favorable: oneConstraintUnfavorable } // FIXME : Voir dans le sources.json si plus tard on a d'autres valeurs
      )
    })
  })
}

const delay = (fun: { (): void; (): void; (): void; (): void; }): void => {
  setTimeout(() => {
    fun()
  }, 200)
}

const getCursorOnMousePosition = (mouseGeoPosition: DegreePosition) => {
  const features = getRenderedFeatures(viewerInstance.project(mouseGeoPosition.toMapboxDegree()))
  // Changer le curseur lors du passage de la souris sur une couche
  if (features.length > 0) {
    return 'help'
  } else {
    return 'default'
  }
}

const state = (): ViewerState => ({
  mouseGeoPosition: DegreePosition.mapBoxLngLat2PositionDegree(new maplibregl.LngLat(0, 0)),
  markerGeoPosition: DegreePosition.mapBoxLngLat2PositionDegree(new maplibregl.LngLat(0, 0)),
  visibleLayersId: [],
  visualizationMode: VisualizationMode.INDIVIDUAL,
  areaMode: AreaMode.ZONE,
  visibleConstraintsIds: [],
  weekNum: getTodayWeek(),
  workType: { id: -1, name: 'none', theme: 'none' },
  syntheses: [],
  constraints: [],
  loading: true,
  mousedown: false,
  movingmap: false
})

const getters = {
  viewerInstance: (): maplibregl.Map => {
    return viewerInstance
  },
  mouseGeoPosition: (state: ViewerState): DegreePosition => {
    return state.mouseGeoPosition
  },
  visibleLayersId: (state: ViewerState): string[] => {
    return state.visibleLayersId
  },
  areaMode: (state: ViewerState): AreaMode => {
    return state.areaMode
  },
  visualizationMode: (state: ViewerState): VisualizationMode => {
    return state.visualizationMode
  },
  visibleConstraintsIds: (state: ViewerState): number[] => {
    return state.visibleConstraintsIds.map(el => parseInt(el))
  },
  weekNum: (state: ViewerState): number => {
    return state.weekNum
  },
  workType: (state: ViewerState): WorkType => {
    return state.workType
  },
  syntheses: (state: ViewerState): Synthesis[] => {
    return state.syntheses
  },
  constraints: (state: ViewerState): Constraint[] => {
    return state.constraints
  },
  loading: (state: ViewerState): boolean => {
    return state.loading
  },
  markerGeoPosition: (state: ViewerState): DegreePosition => {
    return state.markerGeoPosition
  }
}

const mutations = {
  setWeekNum (state: ViewerState, val: number): void {
    state.weekNum = val
  },
  initialize (state: ViewerState, viewerDivId: string): void {
    // Create base map
    viewerInstance = new maplibregl.Map({
      container: viewerDivId,
      style: customBasiskarteStyle as unknown as string, // style URL
      center: [7.35439,46.23069], // starting position [lng, lat]
      zoom: 13 // starting zoom
    })
    viewerInstance.on('moveend', () => {
      state.visibleConstraintsIds = getVisibleConstraintsIds(state.areaMode, state.visualizationMode, state.syntheses)
      delay(() => { renderSynthesis(state.syntheses, state.constraints, state.workType, state.weekNum) })
    })
    viewerInstance.on('mousemove', (event) => {
      state.mouseGeoPosition = DegreePosition.mapBoxLngLat2PositionDegree(event.lngLat.wrap())
    })
    viewerInstance.on('load', function () {
      // Add custom sources
      for (const source of sources.sources){
        viewerInstance.addSource(source.source_id, {
          'type': 'vector',
          'tiles': [
          `${MVT_SERVER}${source.url}`
          ],
          promoteId: 'id'
        });
      }
      // Add custom layers

      // Trier les layers par z-index croissants
      const sortedLayers = sources.layers.sort((layer1, layer2) => {
        // Retrouver la contrainte associée à la couche
        return (layer1?.zIndex ?? 10) - (layer2?.zIndex ?? 10)
      })

      for (const layer of sortedLayers){
        viewerInstance.addLayer(
          {
          'id': layer.layer_id,
          'type': 'fill',
          'source': layer.source_id,
          'source-layer': layer.source_layer,
          'paint': {
            "fill-color": layer.color as unknown as string | maplibregl.StyleFunction | maplibregl.Expression | undefined,
            "fill-opacity": 0.5,
            "fill-outline-color": "#34495e"
           },
           'layout': {
            visibility: layer.visibility as "none" | "visible" | undefined
          },
          'filter': layer?.filter ?? ["all"]
          }
        );
        layers.push(viewerInstance.getLayer(layer.layer_id) as unknown as MapBoxLayer)
      }
      state.visibleConstraintsIds = getVisibleConstraintsIds(state.areaMode, state.visualizationMode, state.syntheses)
      state.loading = false
    })
  },
  setLayerVisibilityLayer (state: ViewerState, { layerId, visible, persistent = false }: { layerId: string, visible: boolean, persistent?: boolean }): void {
    if (viewerInstance.getLayer(layerId)) {
      viewerInstance.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none');
    }
    if (visible) {
      if (!state.visibleLayersId.find(el => el === layerId)) state.visibleLayersId.push(layerId)
    } else {
      // Si on est en mode persistent, laisser la couche visible dans les index (cas ATLAS -> BILAN -> ATLAS)
      if (!persistent) {
        state.visibleLayersId = state.visibleLayersId.filter(el =>  layerId != el)
      }
    }
  },
  updateVisibleConstraintsIds (state: ViewerState): void {
    state.visibleConstraintsIds = getVisibleConstraintsIds(state.areaMode, state.visualizationMode, state.syntheses)
  },
  changeStyle(state: ViewerState,  type: string): void {
    if (type === 'swissimage'){
      viewerInstance.setLayoutProperty('swissimage', 'visibility', 'visible')
    }
    else{
      viewerInstance.setLayoutProperty('swissimage', 'visibility', 'none')
    }
  },
  setMarkerPopupState (state: ViewerState, { numWeek, constraintWeekState }: { numWeek: number, constraintWeekState: ConstraintWeekState }): void {
    popup.setHTML(`
    Semaine ${render2DigitNumber(numWeek)} <br>
    <span class="marker-popup ${constraintWeekState === ConstraintWeekState.FAVORABLE ? 'favorable' : 'not-favorable'}">${constraintWeekState === ConstraintWeekState.FAVORABLE ? 'favorable' : 'défavorable'}</span>
    `)
    if (state.visualizationMode === VisualizationMode.SYNTHESIS && state.areaMode === AreaMode.POSITION) {
      showPopup()
    }
  },
  addMarker (state: ViewerState, degreePosition: DegreePosition): void {
    marker.setLngLat(degreePosition.toMapboxDegree()).addTo(viewerInstance).setDraggable(true)
    state.markerGeoPosition = degreePosition
    state.areaMode = AreaMode.POSITION
    showPopup()
  },
  removeMarker (): void {
    marker?.remove()
  },
  setVisualizationMode (state: ViewerState, visualizationMode: VisualizationMode): void {
    state.visualizationMode = visualizationMode
    if (visualizationMode === VisualizationMode.SYNTHESIS) {
      delay(() => { renderSynthesis(state.syntheses, state.constraints, state.workType, state.weekNum) })
    }

    if (visualizationMode === VisualizationMode.INDIVIDUAL) {
      hidePopup()
    } else {
      if (state.areaMode === AreaMode.POSITION) {
        showPopup()
      }
    }
    state.visibleConstraintsIds = getVisibleConstraintsIds(state.areaMode, state.visualizationMode, state.syntheses)
  },
  setAreaMode (state: ViewerState, areaMode: AreaMode): void {
    const oldAreaMode = state.areaMode
    state.areaMode = areaMode
    if (oldAreaMode !== areaMode && oldAreaMode === AreaMode.POSITION && state.visualizationMode === VisualizationMode.SYNTHESIS) {
      hidePopup()
    } else {
      showPopup()
    }
  },
  setWorkType (state: ViewerState, workType: WorkType): void {
    state.workType = workType
  },
  setSyntheses (state: ViewerState, syntheses: Synthesis[]): void {
    state.syntheses = syntheses
  },
  setConstraints (state: ViewerState, constraints: Constraint[]): void {
    state.constraints = constraints
  },
  setMouseDown (state: ViewerState, value: boolean): void {
    state.mousedown = value
  },
  setMovingMap (state: ViewerState, value: boolean): void {
    state.movingmap = value
  }
}

const actions = {
  async initialize ({ commit, state, dispatch }: { commit: Commit, state: ViewerState, dispatch: Dispatch }, viewerDivId: string): Promise<void> {
    await commit('initialize', viewerDivId)

    const canvas = document.getElementsByClassName("mapboxgl-canvas-container")[0] as HTMLElement
    canvas.addEventListener('mousedown', () => {
      commit('setMouseDown', true)
    })
    canvas.addEventListener('mouseup', () => {
      const mouseGeoPosition = state.mouseGeoPosition
      canvas.style.cursor = getCursorOnMousePosition(mouseGeoPosition)
      if (!state.movingmap) {
        // Placer le marqueur
        dispatch('addMarker', mouseGeoPosition)
      }
      commit('setMouseDown', false)
      commit('setMovingMap', false)
    })
    canvas.addEventListener('mousemove', () => {
      if (state.mousedown) {
        commit('setMovingMap', true)
        canvas.style.cursor = 'grab'
      }
    })
    viewerInstance.on('mousemove', () => {
      const mouseGeoPosition = state.mouseGeoPosition
      canvas.style.cursor = getCursorOnMousePosition(mouseGeoPosition)
    })
  },
  setWeekNum ({ commit, state }: { commit: Commit, state: ViewerState }, val: number): void {
    commit('setWeekNum', val)
    if (state.visualizationMode === VisualizationMode.SYNTHESIS) {
      delay(() => { renderSynthesis(state.syntheses, state.constraints, state.workType, state.weekNum) })
    }
  },
  setLayerVisibilityLayer ({ commit }: { commit: Commit }, value: boolean): void {
    commit('setLayerVisibilityLayer', value)
  },
  async showLayer ({ dispatch }: { dispatch: Dispatch }, mapLayerId: string): Promise<void> {
    await dispatch('setLayerVisibilityLayer', { layerId: mapLayerId, visible: true })
    dispatch('updateVisibleConstraints', false)
  },
  async hideLayer ({ dispatch }: { dispatch: Dispatch }, mapLayerId: string): Promise<void> {
    await dispatch('setLayerVisibilityLayer', { layerId: mapLayerId, visible: false })
    dispatch('updateVisibleConstraints', false)
  },
  updateVisibleConstraints ({ commit, state, dispatch }: { commit: Commit, state: ViewerState, dispatch: Dispatch }, updateConstraintsVisibility = true): void {
    // Mettre à jour les ids des contraintes visibles
    setTimeout(() => {
      commit('updateVisibleConstraintsIds')
      if (updateConstraintsVisibility && state.visualizationMode === VisualizationMode.INDIVIDUAL && !state.loading) {
        // Afficher les nouvelles contraintes visibles
        for (const constraint of state.constraints) {
          if (constraint.associatedToWorkType(state.workType)) {
            dispatch('showLayer', `contraintes_buffer-${constraint.id}`)
          } else {
            dispatch('hideLayer', `contraintes_buffer-${constraint.id}`)
          }
        }
      }
    }, 300) // DIRTY but layer visibility setter has no promise...
  },
  async addMarker ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, degreePosition: DegreePosition): Promise<void> {
    if (marker != null) commit('removeMarker', degreePosition)
    await commit('addMarker', degreePosition)
    marker.on('dragend', () => {
      dispatch('updateVisibleConstraints')
    })
    dispatch('updateVisibleConstraints')
  },
  removeMarker ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): void {
    commit('removeMarker')
    dispatch('updateVisibleConstraints')
  },
  setVisualizationMode ({ commit, state, dispatch }: { commit: Commit, state: ViewerState, dispatch: Dispatch }, visualizationMode: VisualizationMode): void {
    commit('setVisualizationMode', visualizationMode)
    dispatch('updateVisibleConstraints')
    if (visualizationMode === VisualizationMode.SYNTHESIS) {
      for (const layerId of state.visibleLayersId) {
        if (layerId !== BILAN_CONTRAINTE_LAYER_ID) {
          commit('setLayerVisibilityLayer', { layerId: layerId, visible: false, persistent: true })
        }
      }
      commit('setLayerVisibilityLayer', { layerId: BILAN_CONTRAINTE_LAYER_ID, visible: true })
    } else {
      for (const layerId of state.visibleLayersId) {
        if (layerId !== BILAN_CONTRAINTE_LAYER_ID) {
          commit('setLayerVisibilityLayer', { layerId: layerId, visible: true })
        }
      }
      commit('setLayerVisibilityLayer', { layerId: BILAN_CONTRAINTE_LAYER_ID, visible: false })
    }
  },
  setAreaMode ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, areaMode: AreaMode): void {
    commit('setAreaMode', areaMode)
    if (areaMode === AreaMode.ZONE) {
      commit('removeMarker')
    }
    dispatch('updateVisibleConstraints')
  },
  setWorkType ({ commit, state, dispatch }: { commit: Commit, state: ViewerState, dispatch: Dispatch }, value: WorkType): void {
    commit('setWorkType', value)
    dispatch('updateVisibleConstraints')
    if (state.visualizationMode === VisualizationMode.SYNTHESIS) {
      delay(() => { renderSynthesis(state.syntheses, state.constraints, state.workType, state.weekNum) })
    }
  },
  setSyntheses ({ commit }: { commit: Commit }, syntheses: Synthesis[]): void {
    commit('setSyntheses', syntheses)
  },
  setConstraints ({ commit }: { commit: Commit }, constraints: Constraint[]): void {
    commit('setConstraints', constraints)
  },
  setMarkerPopupState ({ commit }: { commit: Commit }, { numWeek, constraintWeekState }: { numWeek: number, constraintWeekState: ConstraintWeekState }): void {
    commit('setMarkerPopupState', { numWeek, constraintWeekState })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
