








import Vue from 'vue'
import ConstraintWeek, { ConstraintWeekState } from './ConstraintWeek'

export default Vue.extend({
  data () {
    return {
      ConstraintWeekState: ConstraintWeekState
    }
  },
  props: {
    selectedWeek: ConstraintWeek
  }
})
