import { getDateWeek } from "@/classes/Utils"

export const BACKEND_PORT = process.env.VUE_APP_BACKEND_PORT
export const BACKEND_REMOTE_HOST = process.env.VUE_APP_BACKEND_REMOTE_HOST
export const BACKEND_REMOTE_URL = `${BACKEND_REMOTE_HOST}${process.env.NODE_ENV === 'production' ? '/api' : `:${BACKEND_PORT}` }`
export const MVT_REMOTE_HOST = process.env.VUE_APP_MVT_REMOTE_HOST
export const MVT_PORT = process.env.VUE_APP_BACKEND_MVT_PORT
export const MVT_SERVER = `${MVT_REMOTE_HOST}${process.env.NODE_ENV === 'production' ? '/mvt' : `:${MVT_PORT}` }`

export const SWISSTOPO_GEOCODER_URL = "https://api3.geo.admin.ch/rest/services/api/SearchServer"
export const ROUTE_GET_CONSTRAINTS = `${BACKEND_REMOTE_URL}/constraints`
export const ROUTE_GET_WORKTYPES = `${BACKEND_REMOTE_URL}/workTypes`
export const ROUTE_GET_SYNTHESES = `${BACKEND_REMOTE_URL}/syntheses`

export const NB_WEEKS = getDateWeek(new Date(new Date().getFullYear(), 11, 30))
