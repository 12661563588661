


























































import Vue from 'vue'
import Constraint from '@/classes/Constraint/Constraint'
import PeriodSelector from '../PeriodSelector/PeriodSelector.vue'
import layersStyles from '@/data/sources.json';
import { mapGetters } from 'vuex'
import { VIEWER_ACTION_HIDE_LAYER, VIEWER_ACTION_SHOW_LAYER, VIEWER_GETTER_LOADING, VIEWER_GETTER_VISIBLE_LAYERS_ID, VIEWER_MODULE_NAME, VisualizationMode } from '@/store';
import ConstraintWeek, { ConstraintWeekState } from '../PeriodSelector/ConstraintWeek';
import { NB_WEEKS } from '@/constants';

const getCorrespondingLayer = (constraint: Constraint) => {
  let correspondingLayer = layersStyles.layers.find(layer => layer.db_id && layer.db_id === constraint.id && layer.source_layer === 'contraintes_buffer')
  if (!correspondingLayer) {
    console.error(`Couche Mapbox introuvable : contrainte ${constraint.name}, id ${constraint.id}`)
    return null
  }
  else {
    return correspondingLayer
  }
}

export default Vue.extend({
  props: {
    visualizationMode: Number,
    constraint: Constraint,
    showActive: Boolean,
    selected: Boolean
  },
  data() {
    return {
      showMore: false
    }
  },
  watch: {
    loading () {
      // eslint-disable-next-line
      (this as any).updateLayerVisibility()
    },
    disabled () {
      // eslint-disable-next-line
      (this as any).updateLayerVisibility()
    }
  },
  methods: {
    updateLayerVisibility (): void {
      if (this.disabled) {
        this.$store.dispatch(VIEWER_ACTION_HIDE_LAYER, this.layerId)
      } else {
        this.$store.dispatch(VIEWER_ACTION_SHOW_LAYER, this.layerId)
      }
    }
  },
  computed: {
    ...mapGetters(VIEWER_MODULE_NAME, {
      loading: VIEWER_GETTER_LOADING
    }),
    disabled(): boolean {
      return !this.constraint.matchingWorkType
    },
    constraintsWeeks(): ConstraintWeek[] {
      const res = []
      for (let numWeek = 1; numWeek <= NB_WEEKS; numWeek++) {
        let periodOverlapsWeek = false

        for (const period of this.constraint.periods) {
          periodOverlapsWeek = period.overlapsWeek(numWeek)
          if (periodOverlapsWeek) break          
        }
        res.push(new ConstraintWeek(numWeek - 1, periodOverlapsWeek ? ConstraintWeekState.UNFAVORABLE : ConstraintWeekState.FAVORABLE ))
      }
      return res
    },
    showIndividualSymbology() {
      return this.visualizationMode === VisualizationMode.INDIVIDUAL
    },
    getSymbologyBackground () {
      const correspondingLayer = getCorrespondingLayer(this.constraint as Constraint)
      return correspondingLayer ? correspondingLayer.color : ''
    },
    layerId () {
      const correspondingLayer = getCorrespondingLayer(this.constraint as Constraint)
      return correspondingLayer ? correspondingLayer.layer_id : ''
    },
    ...mapGetters(VIEWER_MODULE_NAME, {
      layersVisibility: VIEWER_GETTER_VISIBLE_LAYERS_ID
    }),
    showLayer: {
      get () {
        return this.layersVisibility.includes(this.layerId)
      },
      set (value) {
        if (value) {
          this.$store.dispatch(VIEWER_ACTION_SHOW_LAYER, this.layerId)
        } else {
          this.$store.dispatch(VIEWER_ACTION_HIDE_LAYER, this.layerId)
        }
      }
    },
    showWorkTypes () {
      return this.visualizationMode !== VisualizationMode.SYNTHESIS
    }
  },
  components: { PeriodSelector: PeriodSelector }
})
