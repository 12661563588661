





























import Vue from 'vue';
import { VIEWER_ACTION_CHANGE_STYLE } from '@/store'


export default Vue.extend({
  data () {
    return {
      layer: 'basiskarte',
      toggleSelect: false,
      basemaps: [
        { text: "swissmap", value: "swissmap" },
        { text: "swissimage", value: "swissimage" }
    ],
    }
  },
  methods: {
    changeBaseMap (basemap: string) {
      this.$store.commit(VIEWER_ACTION_CHANGE_STYLE, basemap)
      this.toggleSelect = false
    }
  }
});
