import { getMondayOfWeek, getSundayOfWeek } from "../Utils"

export interface SerializedConstraintPeriod {
  name: string
  dateBegin: string
  dateEnd: string
}

export default class ConstraintPeriod {
  name: string
  dateBegin: Date
  dateEnd: Date

  constructor (name: string, dateBegin: Date, dateEnd: Date) {
    this.name = name
    this.dateBegin = dateBegin
    this.dateEnd = dateEnd
  }

  contains (date: Date): boolean {
    return this.dateBegin.getTime() <= date.getTime() && this.dateEnd.getTime() >= date.getTime()
  }

  overlapsWeek (numWeek: number): boolean {
    const monday = getMondayOfWeek(numWeek)
    const sunday = getSundayOfWeek(numWeek)
    return this.contains(monday) || this.contains(sunday)
  }

  static createFromSerialized(serializedPeriod: SerializedConstraintPeriod): ConstraintPeriod {
    return new ConstraintPeriod(serializedPeriod.name, new Date(serializedPeriod.dateBegin), new Date(serializedPeriod.dateEnd))
  }
}