import Ajv from 'ajv'

const ajv = new Ajv()

export default class SynthesisValidator {
  public static validateSynthesis(synthesises: Array<unknown>): void {

    const synthesisSchema = {
      type: "object",
      properties: {
        id: { type: 'integer' },
        constraintsIds: { 
          type: 'array',
          items: {
            type: "integer"
          }
        },
      },
      required: ['id', 'constraintsIds'],
      additionalProperties: false
    }

    const schema = {
      type: "array",
      items: synthesisSchema
    }

    const validate = ajv.compile(schema)
    const valid = validate(synthesises)
    if (!valid) throw validate.errors
  }
}