











import Vue from 'vue'
export default Vue.extend({
  computed: {
    hasHeaderContent() {
      return !!this.$slots.header || !!this.$slots["post-header"]
    }
  }
})
