



















import Vue from 'vue'
import PeriodSelector from './PeriodSelector/PeriodSelector.vue'
import VisualizationModeSelector from './VisualizationModeSelector.vue'
import ConstraintsWrapper from './ConstraintsWrapper/ConstraintsWrapper.vue'
import { getAllWorkTypesRequest } from '@/requests/workTypes'
import ConstraintWeek, { ConstraintWeekState } from './PeriodSelector/ConstraintWeek'
import { NB_WEEKS } from '@/constants'
import Constraint from '@/classes/Constraint/Constraint'
import { AreaMode, VIEWER_ACTION_SET_VISUALIZATION_MODE, VIEWER_GETTER_AREA_MODE, VIEWER_GETTER_VISUALIZATION_MODE, VIEWER_GETTER_VISIBLE_CONSTRAINTS_IDS, VIEWER_GETTER_CONSTRAINTS, VIEWER_MODULE_NAME, VisualizationMode, VIEWER_ACTION_SET_WORKTYPE, VIEWER_GETTER_WORKTYPE, VIEWER_GETTER_WEEKNUM, VIEWER_ACTION_SET_MARKER_POPUP_STATE } from '@/store'
import { mapGetters } from 'vuex'
import WorkType from '@/classes/WorkType'

export default Vue.extend({
  data () {
    return {
      workTypes: [],
      AreaMode: AreaMode
    }
  },
  async mounted () {
    try {
      this.workTypes = await getAllWorkTypesRequest() as never
      this.$store.dispatch(VIEWER_ACTION_SET_WORKTYPE, this.workTypes[0])
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    changeVisualizationMode(visualizationMode: VisualizationMode) {
      this.$store.dispatch(VIEWER_ACTION_SET_VISUALIZATION_MODE, visualizationMode)
    },
    checkUpdateMarkerPopupState () {
      if (this.visualizationMode === VisualizationMode.SYNTHESIS && this.areaMode === AreaMode.POSITION) {
        this.$store.dispatch(VIEWER_ACTION_SET_MARKER_POPUP_STATE, { numWeek: this.weekNum, constraintWeekState: (this.constraintsWeeks[this.weekNum] as ConstraintWeek).state })
      }
    }
  },
  computed: {
    ...mapGetters(VIEWER_MODULE_NAME, {
      visibleConstraintsIds: VIEWER_GETTER_VISIBLE_CONSTRAINTS_IDS,
      visualizationMode: VIEWER_GETTER_VISUALIZATION_MODE,
      areaMode: VIEWER_GETTER_AREA_MODE,
      getSelectedWorkType: VIEWER_GETTER_WORKTYPE,
      constraints: VIEWER_GETTER_CONSTRAINTS,
      weekNum: VIEWER_GETTER_WEEKNUM
    }),
    selectedWorkType: {
      get (): WorkType {
        return this.getSelectedWorkType
      },
      set (workTypeIndex: WorkType): void {
        const index = workTypeIndex as unknown as number
        this.$store.dispatch(VIEWER_ACTION_SET_WORKTYPE, this.workTypes.find((el: WorkType) => el.id === index))
      }
    },
    prerenderedConstraints(): Constraint[] {
      for (const constraint of this.constraints as Constraint[]) {
        constraint.visible = this.visibleConstraintsIds.includes(constraint.id)
        constraint.matchingWorkType = this.selectedWorkType && constraint.workTypes.map(workType => workType.id).includes(this.selectedWorkType.id)
        if (this.visualizationMode === VisualizationMode.SYNTHESIS) {
          constraint.setFavorableWeek(this.weekNum)
        } else {
          constraint.favorableWeek = -1 // FIXME ENUM
        }
      }

      let res: Constraint[] = [...this.constraints]
      if (this.visualizationMode === VisualizationMode.SYNTHESIS || this.areaMode === AreaMode.POSITION) {
        res = res.filter(el => el.visible)
      }

      // const visibleConstaints
      res.sort((a: Constraint, b: Constraint) => b.getOrder() - a.getOrder())
      return res
    },
    showPeriodSelector(): boolean {
      return this.visualizationMode === VisualizationMode.SYNTHESIS
    },
    constraintsWeeks(): ConstraintWeek[] {
      const res = []
      if (this.visualizationMode === VisualizationMode.SYNTHESIS) {
        for (let numWeek = 1; numWeek <= NB_WEEKS; numWeek++) {
          if (this.areaMode === AreaMode.ZONE) {
            res.push(new ConstraintWeek(numWeek - 1, ConstraintWeekState.NONE ))
          } else {
            // Déterminer si une contrainte n'est pas favorable à la période
            let oneConstraintUnfavorable = false
            // Récupérer les contraintes liées au type de travaux
            for (const constraint of (this.prerenderedConstraints.filter(el => el.matchingWorkType && el.visible))) {
              if (!constraint.isFavorableDuringWeek(numWeek)) {
                oneConstraintUnfavorable = true
                break
              }
            }
            res.push(new ConstraintWeek(numWeek - 1, oneConstraintUnfavorable ? ConstraintWeekState.UNFAVORABLE : ConstraintWeekState.FAVORABLE ))
          }
        }
      }
      return res
    },
  },
  watch: {
    areaMode () {
      this.checkUpdateMarkerPopupState()
    },
    visualizationMode () {
      this.checkUpdateMarkerPopupState()
    },
    markerPosition () {
      this.checkUpdateMarkerPopupState()
    }
  },
  components: { PeriodSelector: PeriodSelector, VisualizationModeSelector: VisualizationModeSelector, ConstraintsWrapper: ConstraintsWrapper }
})

