









































import Vue from 'vue'
import ConstraintsViewerSection from '../ConstraintsViewerSection.vue'
import Constraint from './Constraint.vue'
import CConstraint from '@/classes/Constraint/Constraint'
import { VIEWER_ACTION_SET_AREA_MODE, AreaMode, VIEWER_MODULE_NAME, VIEWER_GETTER_AREA_MODE } from '@/store'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: ['constraints', 'visualizationMode'],
  components: { ConstraintsViewerSection: ConstraintsViewerSection, Constraint: Constraint },
  methods: {
    setZoneMode () {
      this.$store.dispatch(VIEWER_ACTION_SET_AREA_MODE, AreaMode.ZONE)
    }
  },
  computed: {
    ...mapGetters(VIEWER_MODULE_NAME, {
      areaMode: VIEWER_GETTER_AREA_MODE
    }),
    matchingWorkTypeConstraints () {
      return (this.constraints as CConstraint[]).filter(constraint => constraint.matchingWorkType)
    },
    notMotchingWorkTypeConstraints () {
      return (this.constraints as CConstraint[]).filter(constraint => !constraint.matchingWorkType)
    }
  },
  data () {
    return {
      showNotMotchingWorkTypeConstraints: false,
      AreaMode: AreaMode
    }
  }
})
