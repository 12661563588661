import BoundingBox from '@/classes/BoundingBox'
import DegreePosition from '@/classes/DegreePosition'
import { epsg2056ToDegreePosition } from '@/classes/Utils'

const MAX_NUMBER_RENDERED_RESULTS = 6

enum ResponseLocationType {
  POSITION = "position",
  COMMUNE = "gg25",
  DISTRICT = "district",
  CANTON = "kantone",
  ADDRESS = "address",
  UNKNOWN = "unknown"
}

export enum LocationType {
  POSITION = "POSITION",
  COMMUNE = "COMMUNE",
  DISTRICT = "DISTRICT",
  CANTON = "CANTON",
  ADDRESS = "ADDRESS",
  UNKNOWN = "UNKNOWN"
}

export const searchResultGroupLabel: { [P in LocationType]: string  } = {
  COMMUNE: "Commune",
  DISTRICT: "District",
  CANTON: "Canton",
  ADDRESS: "Adresse",
  POSITION: "Coordonnées EN",
  UNKNOWN: "Inconnu",
}

export const resultGroupOrder: { [P in LocationType]: number  } = {
  COMMUNE: 0,
  DISTRICT: 1,
  CANTON: 2,
  ADDRESS: 2,
  POSITION: -1,
  UNKNOWN: -2
}

export class SearchResultItem {
  type: LocationType = LocationType.UNKNOWN
  name: string
  position: DegreePosition
  zoom: number
  boundingBox: BoundingBox

  constructor (type: ResponseLocationType, name: string, lat: number, long: number, zoom: number, boundingBox: BoundingBox) {
    switch (type) {
      case ResponseLocationType.COMMUNE:
        this.type = LocationType.COMMUNE
        break
      case ResponseLocationType.DISTRICT:
        this.type = LocationType.DISTRICT
        break
      case ResponseLocationType.CANTON:
        this.type = LocationType.CANTON
        break
      case ResponseLocationType.ADDRESS:
        this.type = LocationType.ADDRESS
        break
      case ResponseLocationType.POSITION:
        this.type = LocationType.POSITION
        break
      default:
        this.type = LocationType.UNKNOWN
    }
    // Nettoyer le nom de la localisation
    this.name = name.replace('<b>', '').replace('</b>', '')
    this.position = new DegreePosition(long, lat)
    this.zoom = zoom
    this.boundingBox = boundingBox
  }

  getTypeLabel(): string {
    return searchResultGroupLabel[this.type]
  }
}

export const formatSearchResults = (data: Record<string, unknown>, searchString: string): SearchResultItem[] => {
  const filteredResults = (data.results as Record<string, unknown>[]).filter(result => {
    const properties = result.attrs as Record<string, unknown>
    return Object.values(ResponseLocationType).includes(properties.origin as ResponseLocationType)
  })

  let searchResultItems = filteredResults.map(result => {
    const properties = result.attrs as Record<string, unknown>
    return new SearchResultItem(properties.origin as ResponseLocationType, properties.label as string, properties.lat as number, properties.lon as number, properties.zoomlevel as number, BoundingBox.createFromGeomStBox2d(properties.geom_st_box2d as string))
  })

  // Limiter le nombre de résultats affichés
  if (searchResultItems.length > MAX_NUMBER_RENDERED_RESULTS) {
    searchResultItems = searchResultItems.slice(0, MAX_NUMBER_RENDERED_RESULTS)
  }

  // Tester si la chaîne de caractère de recherche correspond à une coordonnée
  const geoCoordinatesRegex = new RegExp('^(?<eCoords>[0-9]+(\\.[0-9]+)?)\\s*,\\s*(?<nCoords>[0-9]+(\\.[0-9]+)?)$')
  if (geoCoordinatesRegex.test(searchString)) {
    const match = geoCoordinatesRegex.exec(searchString)
    const nCoords = match?.groups?.nCoords
    const eCoords = match?.groups?.eCoords
    if (nCoords && eCoords) {
      const parsedECoord = parseFloat(eCoords)
      const parsedNCoord = parseFloat(nCoords)
      const offset = 0.0005
      const to4326 = epsg2056ToDegreePosition(parsedECoord, parsedNCoord)
      const boundingBox = new BoundingBox(to4326.lat - offset, to4326.lon - offset, to4326.lat + offset, to4326.lon + offset)
      searchResultItems.push(new SearchResultItem(ResponseLocationType.POSITION, `E: ${eCoords}, N: ${nCoords}`, to4326.lon, to4326.lat, 14, boundingBox)) // FIXME lat long inversés ???
    }
  }

  // Ordonner les résultats selon les types de localisations
  searchResultItems.sort((a, b): number => {
    return resultGroupOrder[a.type] - resultGroupOrder[b.type]
  })

  return searchResultItems
}
