import Ajv from 'ajv'

const ajv = new Ajv()

export default class ConstraintValidator {
  public static validateConstraints(constraints: Array<Record<string, unknown>>): void {

    const innerWorkTypesSchema = {
      type: 'object',
      properties: {
        id: { type: 'integer' },
        name: { type: 'string' },
        theme: { type: 'integer' }
      },
      required: ['id', 'name', 'theme'],
      additionalProperties: false
    }

    const innerPeriodSchema = {
      type: 'object',
      properties: {
        name: { type: 'string' },
        dateEnd: { type: 'string' },
        dateBegin: { type: 'string' }
      },
      required: ['name', 'dateEnd', 'dateBegin'],
      additionalProperties: false
    }

    const constraintSchema = {
      type: "object",
      properties: {
        id: { type: 'integer' },
        name: { type: 'string' },
        source: { type: 'string' },
        priority: { type: 'integer' },
        workTypes: {
          type: 'array',
          items: innerWorkTypesSchema
        },
        periods: { 
          type: 'array',
          items: innerPeriodSchema
        }
      },
      required: ['id', 'name', 'source', 'workTypes', 'periods', 'priority'],
      additionalProperties: false
    }

    const schema = {
      type: "array",
      items: constraintSchema
    }

    const validate = ajv.compile(schema)
    const valid = validate(constraints)
    if (!valid) throw validate.errors
  }
}