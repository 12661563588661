


















































import { mapGetters } from 'vuex';
import Vue from 'vue';
import { VIEWER_GETTER_LOADING, VIEWER_MODULE_NAME } from './store';
import { render2DigitNumber } from './classes/Utils';

const date = new Date()
const renderedDate = `${render2DigitNumber(date.getDate())}-${render2DigitNumber(date.getMonth() + 1)}-${render2DigitNumber(date.getFullYear())}`

export default Vue.extend({
  name: 'App',
  data () {
    return {
      valid: false,
      passCorrect: false,
      accessPass: '',
      accessPassRules : [
        (v: string) => !!v || 'Mot de passe requis',
        (v: string) => v === `a58C4BF5we-${renderedDate}` || 'Mot de passe incorrect'
      ]
    }
  },
  methods: {
    validate () {
      (this.$refs.form as HTMLFormElement).validate()
      this.passCorrect = this.valid
    }
  },
  computed: {
    ...mapGetters(VIEWER_MODULE_NAME, {
      loading: VIEWER_GETTER_LOADING
    })
  }
});
