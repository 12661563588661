import Ajv from 'ajv'

const ajv = new Ajv()

export default class WorkTypeValidator {
  public static validateWorkTypes(workType: Array<Record<string, unknown>>): void {

    const workTypesSchema = {
      type: 'object',
      properties: {
        id: { type: 'integer' },
        name: { type: 'string' },
        theme: { type: 'string' }
      },
      required: ['id', 'name', 'theme'],
      additionalProperties: false
    }

    const schema = {
      type: "array",
      items: workTypesSchema
    }

    const validate = ajv.compile(schema)
    const valid = validate(workType)
    if (!valid) throw validate.errors
  }
}