














































import Vue from 'vue';
import { searchLocationRequest } from '@/requests'
import { formatSearchResults, SearchResultItem } from './searchUtils'
import { VIEWER_GETTER_VIEWER_INSTANCE, VIEWER_MODULE_NAME, VIEWER_ACTION_ADD_MARKER, VIEWER_ACTION_REMOVE_MARKER, VIEWER_GETTER_AREA_MODE, VIEWER_GETTER_VISUALIZATION_MODE, AreaMode, VIEWER_ACTION_SET_AREA_MODE } from '@/store'
import { LocationType } from './searchUtils'
import { mapGetters } from 'vuex'
import DegreePosition from '@/classes/DegreePosition';

export default Vue.extend({
  data () {
    return {
      showMenu: false,
      searchString: '',
      searchResults: [] as SearchResultItem[],
      loading: false,
      AreaMode: AreaMode
    }
  },
  computed: {
    searching (): boolean {
      return this.showMenu && !!this.searchString && this.searchString.trim().length > 0
    },
    ...mapGetters(VIEWER_MODULE_NAME, {
      viewerInstance: VIEWER_GETTER_VIEWER_INSTANCE,
      visualizationMode: VIEWER_GETTER_VISUALIZATION_MODE,
      areaMode: VIEWER_GETTER_AREA_MODE
    })
  }, // TODO RECHERCHER PAR RAPPORT A UNE COORDONNEE
  methods: {
    setZoneMode () {
      this.$store.dispatch(VIEWER_ACTION_SET_AREA_MODE, AreaMode.ZONE)
    },
    lookAt (searchResult: SearchResultItem, setMarker?: boolean) {
      this.showMenu = false
      this.viewerInstance.fitBounds(searchResult.boundingBox.getDisplayBound().toMapBoxBound())
      this.searchString = searchResult.name
      if (setMarker || searchResult.type === LocationType.ADDRESS || searchResult.type === LocationType.POSITION) {
        this.setMarker(searchResult.position)
        this.$store.dispatch(VIEWER_ACTION_SET_AREA_MODE, AreaMode.POSITION)
      } else {
        this.$store.dispatch(VIEWER_ACTION_REMOVE_MARKER)
        this.setZoneMode()
      }
    },
    setMarker (position: DegreePosition): void {
      this.$store.dispatch(VIEWER_ACTION_ADD_MARKER, position)
    },
    renderResult (searchResult: SearchResultItem) {
      return searchResult.name.replace(new RegExp(this.searchString, "gi"), match => {
        return '<span class="highlighted-text">' + match + '</span>'
      })
    },
    async searchLocation (renderLoading: boolean) {
      this.showMenu = true
      const searchString = this.searchString || ''
      if (searchString.trim().length > 0) {
        try {
          if (renderLoading) {
            this.loading = true
          }
          
          const response = await searchLocationRequest(searchString)
          setTimeout(() => {
            if (renderLoading) {
              this.loading = false
            }
          }, 300)
          if (response.status === 200) {
            this.searchResults = formatSearchResults(response.data as Record<string, unknown>, searchString)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        this.showMenu = false
      }
    }
  }
});
