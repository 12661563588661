




import Vue from 'vue'
import { VIEWER_ACTION_INITIALIZE, VIEWER_ACTION_SET_SYNTHESES, VIEWER_ACTION_SET_CONSTRAINTS } from '@/store'
import { getSynthesesRequest } from '@/requests/synthesis'
import { getAllConstraintsRequest } from '@/requests/constraints';

export default Vue.extend({
  components: {
  },
  async mounted () {
    const syntheses = await getSynthesesRequest()
    this.$store.dispatch(VIEWER_ACTION_SET_SYNTHESES, syntheses)
    const constraints = await getAllConstraintsRequest()
    await this.$store.dispatch(VIEWER_ACTION_SET_CONSTRAINTS, constraints)
    await this.$store.dispatch(VIEWER_ACTION_INITIALIZE, 'map-viewer')
  }
})

