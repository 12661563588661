








import { Component, Vue } from 'vue-property-decorator';
import Viewer from '@/components/Viewer.vue';
// import SearchBar from '@/components/SearchBar/SearchBar.vue';
import LayerManager from '@/components/LayerManager/LayerManager.vue';
import SideBar from '@/components/SideBar/SideBar.vue';

@Component({
  components: {
    Viewer: Viewer, SideBar: SideBar, LayerManager: LayerManager
  },
})
export default class Home extends Vue {}
