












import { VisualizationMode } from '@/store'
import Vue from 'vue'
import ConstraintsViewerSection from './ConstraintsViewerSection.vue'

export default Vue.extend({
  data() {
    return {
      viewerModeIndex: 0
    }
  },
  props: {
    changeVisualizationMode: Function
  },
  methods: {
    handleChangeVisualizationMode() {
      const visualizationMode = this.viewerModeIndex === 0 ? VisualizationMode.INDIVIDUAL : VisualizationMode.SYNTHESIS
      this.changeVisualizationMode(visualizationMode)
    }
  },
  components: { ConstraintsViewerSection: ConstraintsViewerSection }
})
