































import Vue from 'vue'
import ConstraintWeek, { ConstraintWeekState } from './ConstraintWeek'
import { getMondayOfWeek, getSundayOfWeek, getTodayWeek, renderDate } from '@/classes/Utils'
import ConstraintsViewerSection from '../ConstraintsViewerSection.vue'
import ChipWeekState from './ChipWeekState.vue'
import { NB_WEEKS } from '@/constants'
import { VisualizationMode, AreaMode, VIEWER_MODULE_NAME, VIEWER_GETTER_WEEKNUM, VIEWER_ACTION_SET_WEEKNUM, VIEWER_ACTION_SET_MARKER_POPUP_STATE, VIEWER_GETTER_MARKER_GEO_POSITION } from '@/store'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    minVersion: Boolean,
    visualizationMode: Number,
    areaMode: Number,
    constraintsWeeks: Array,
    globalWeekNum: Boolean
  },
  data () {
    return {
      ConstraintWeekState: ConstraintWeekState,
      localWeekNum: getTodayWeek(),
      nbWeeks: NB_WEEKS
    }
  },
  computed: {
    weekIndex: {
      set (value: string) {
        const val = parseInt(value)
        if (this.globalWeekNum) {
          this.$store.dispatch(VIEWER_ACTION_SET_WEEKNUM, val + 1)
          if (this.areaMode === AreaMode.POSITION && this.visualizationMode === VisualizationMode.SYNTHESIS) {
            this.$store.dispatch(VIEWER_ACTION_SET_MARKER_POPUP_STATE, { numWeek: val + 1, constraintWeekState: (this.constraintsWeeks[val] as ConstraintWeek).state })
          }
        } else {
          this.localWeekNum = val + 1
        }
      },
      get (): number {
        return this.globalWeekNum ? this.weekNum - 1 : this.localWeekNum - 1
      }
    },
    ...mapGetters(VIEWER_MODULE_NAME, {
      weekNum: VIEWER_GETTER_WEEKNUM,
      markerPosition : VIEWER_GETTER_MARKER_GEO_POSITION
    }),
    showSynthesisAssets(): boolean {
      return this.minVersion || (this.visualizationMode === VisualizationMode.SYNTHESIS && this.areaMode === AreaMode.POSITION)
    },
    showTimeline(): boolean {
      return this.minVersion || (this.visualizationMode === VisualizationMode.SYNTHESIS)
    },
    selectedWeek(): ConstraintWeek {
      return (this.constraintsWeeks as ConstraintWeek[])[this.weekIndex as number]
    },
    mondayWeek(): Date {
      return getMondayOfWeek(this.selectedWeek.numWeek)
    },
    renderedMondayWeek(): string {
      return renderDate(this.mondayWeek)
    },
    sundayWeek(): string {
      return renderDate(getSundayOfWeek(this.selectedWeek.numWeek))
    }
  },
  components: { ConstraintsViewerSection: ConstraintsViewerSection, ChipWeekState: ChipWeekState }
})
