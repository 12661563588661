import WorkType from "../WorkType"
import ConstraintPeriod, { SerializedConstraintPeriod } from "./ConstraintPeriod"

export interface SerializedConstraint {
  name: string
  id: number
  source: string
  priority: number
  workTypes: WorkType[]
  periods: SerializedConstraintPeriod[]
}

export default class Constraint {
  name: string
  id: number
  source: string
  priority: number
  workTypes: WorkType[]
  periods: ConstraintPeriod[]
  visible = false
  matchingWorkType = false
  selected = false
  favorableWeek = -1 // Indique si la contrainte est favorable sur le numéro de semaine courant // FIXME ENUM

  constructor(name: string, id: number, source: string, priority: number) {
    this.name = name
    this.id = id
    this.source = source
    this.workTypes = []
    this.periods = []
    this.priority = priority
  }

  setFavorableWeek(weekNum: number): void {
    this.favorableWeek = this.isFavorableDuringWeek(weekNum) ? 1 : 0
  }

  associatedToWorkType(workType: WorkType): boolean {
    return !!this.workTypes.find(el => workType.id === el.id)
  }

  isFavorableDuringWeek(weekNum: number): boolean {
    for (const period of this.periods) {
      if (period.overlapsWeek(weekNum)) {
        return false
      }
    }
    return true
  }

  getOrder(): number {
    let res = this.priority
    if (!this.favorableWeek) {
      res +=100
    }
    return res
  }

  addWorkType(workType: WorkType): void {
    this.workTypes.push(workType)
  }

  addPeriod(period: ConstraintPeriod): void {
    this.periods.push(period)
  }

  static deserialize(serializedConstraint: SerializedConstraint): Constraint {
    const res = new Constraint(serializedConstraint.name, serializedConstraint.id, serializedConstraint.source, serializedConstraint.priority)
    res.workTypes = serializedConstraint.workTypes
    res.periods = serializedConstraint.periods.map(serializedPeriod => {
      return new ConstraintPeriod(serializedPeriod.name, new Date(serializedPeriod.dateBegin), new Date(serializedPeriod.dateEnd))
    })
    return res
  }
}