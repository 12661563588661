export default class BoundingBox {
  startLat: number
  endLat: number
  startLon: number
  endLon: number

  constructor (startLat: number, startLon: number, endLat: number, endLon: number) {
    this.startLat = startLat
    this.endLat = endLat
    this.startLon = startLon
    this.endLon = endLon
  }

  static createFromGeomStBox2d (geombox: string): BoundingBox {
    geombox = geombox.replace("BOX", "").replace("(", "").replace(")", "")
    const splittedGeomBox = geombox.split(",")
    const bboxBegin = splittedGeomBox[0].split(" ").map(el => parseFloat(el))
    const bboxEnd = splittedGeomBox[1].split(" ").map(el => parseFloat(el))
    return new BoundingBox(bboxBegin[0], bboxBegin[1], bboxEnd[0], bboxEnd[1])
  }

  toMapBoxBound (): number[][] {
    return [
      [this.startLat, this.startLon], [this.endLat, this.endLon]
    ]
  }

  /**
   * Retourne le rectangle englobant adapté à l'affichage
   * Utile lors des focus sur les objets à faible emprise 
   */
   getDisplayBound (): BoundingBox {
    const offsetMinDisplayBoundingBox = .002
    const boundingBoxMaxDegree = Math.max(this.endLat - this.startLat, this.endLon - this.startLon)
    if (offsetMinDisplayBoundingBox > boundingBoxMaxDegree) {
      return new BoundingBox(
        this.startLat - offsetMinDisplayBoundingBox,
        this.startLon - offsetMinDisplayBoundingBox,
        this.endLat + offsetMinDisplayBoundingBox,
        this.endLon + offsetMinDisplayBoundingBox
      )
    } else {
      return this
    }
  }
}