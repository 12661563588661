export enum ConstraintWeekState {
  FAVORABLE = "FAVORABLE",
  UNFAVORABLE = "UNFAVORABLE",
  NONE = "NONE"
}

export default class ConstraintWeek {
  index: number
  numWeek: number
  state: ConstraintWeekState

  constructor (index: number, state: ConstraintWeekState) {
    this.index = index
    this.numWeek = index + 1
    this.state = state
  }
}