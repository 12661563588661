import * as maplibregl from 'maplibre-gl'

export default class DegreePosition {
  lon: number
  lat: number

  constructor (lon: number, lat: number) {
    this.lon = lon
    this.lat = lat
  }

  static mapBoxLngLat2PositionDegree(mapboxLngLat: maplibregl.LngLat): DegreePosition {
    return new DegreePosition(mapboxLngLat.lng, mapboxLngLat.lat)
  }

  toMapboxDegree (): maplibregl.LngLatLike {
    return [this.lon, this.lat]
  }
}