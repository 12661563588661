import { ROUTE_GET_WORKTYPES } from '@/constants';
import WorkType from '@/classes/WorkType';
import WorkTypeValidator from '@/validators/WorkTypeValidator';
import axios from 'axios';

export const getAllWorkTypesRequest = async (): Promise<WorkType[]> => {
  const serializedWorkTypes: Array<Record<string, unknown>> = (await axios.get(ROUTE_GET_WORKTYPES)).data
  WorkTypeValidator.validateWorkTypes(serializedWorkTypes)
  return (serializedWorkTypes as unknown as WorkType[])
}
