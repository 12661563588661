









import Vue from 'vue'
import SearchBar from './SearchBar/SearchBar.vue'
import ConstraintsViewer from './ConstraintsViewer/ConstraintsViewer.vue'

export default Vue.extend({
  components: { SearchBar: SearchBar, ConstraintsViewer: ConstraintsViewer }
})
